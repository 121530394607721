import { render, staticRenderFns } from "./vs-form-file.vue?vue&type=template&id=536d1599&scoped=true"
import script from "./vs-form-file.vue?vue&type=script&lang=js"
export * from "./vs-form-file.vue?vue&type=script&lang=js"
import style0 from "./vs-form-file.vue?vue&type=style&index=0&id=536d1599&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "536d1599",
  null
  
)

export default component.exports