import components from '@/misc/import-file.js';
import modalPlugins from '@/components/plugins/modal-plugins.js';

const install = (Vue) => {
  Vue.use(modalPlugins);

  Object.entries(components).forEach((component) => {
    const [key, name] = component;

    Vue.component(key, name);
  });
};

const library = {
  components,
  install,
};

window.vsCore = library;

export default library;
