var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.options.length)?[(_vm.inputStyle === 'borderless')?_c('fieldset',{staticClass:"vs-form-checkbox",class:[_vm.orientation === 'vertical' ? 'flex-col' : 'flex-row', 'flex gap-1'],attrs:{"id":_vm.id}},_vm._l((_vm.options),function(option){return _c('div',{key:option,staticClass:"flex items-center p-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.vModel),expression:"vModel"}],staticClass:"vst-radio__input transition-all shrink-0 !m-0 mt-0.5 border-gray-200 rounded-full",class:[`text-${_vm.color}-600`],attrs:{"id":_vm.getOptionLabel(option) + _vm.id,"name":_vm.id,"disabled":_vm.disabled,"type":"radio"},domProps:{"value":option?.id || _vm.getOptionLabel(option),"checked":_vm._q(_vm.vModel,option?.id || _vm.getOptionLabel(option))},on:{"input":function($event){return _vm.selectValue(option)},"change":function($event){_vm.vModel=option?.id || _vm.getOptionLabel(option)}}}),_c('label',{staticClass:"transition-all text-gray-500 ml-2 mb-0 font-normal",class:{
            'text-xs': _vm.size === 'mini',
            'text-sm': _vm.size === 'default',
            'text-lg': _vm.size === 'large',
            'cursor-not-allowed opacity-50': _vm.disabled,
            'cursor-pointer': !_vm.disabled,
          },attrs:{"for":_vm.getOptionLabel(option) + _vm.id}},[_vm._v(_vm._s(_vm.getOptionLabel(option)))])])}),0):_c('div',{class:[_vm.orientation === 'vertical' ? 'flex-col' : 'flex-row', 'flex gap-1'],attrs:{"id":_vm.id}},_vm._l((_vm.options),function(option){return _c('div',{key:option,staticClass:"flex items-center p-1"},[_c('label',{staticClass:"max-w-xs flex p-3 block w-full bg-white border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500",class:{
            'cursor-not-allowed opacity-50': _vm.disabled,
            'cursor-pointer': !_vm.disabled,
          }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.vModel),expression:"vModel"}],staticClass:"vst-radio__input transition-all shrink-0 !m-0 mt-0.5 border-gray-200 rounded-full",class:[`text-${_vm.color}-600`],attrs:{"name":_vm.id,"disabled":_vm.disabled,"type":"radio"},domProps:{"value":option?.id || _vm.getOptionLabel(option),"checked":_vm._q(_vm.vModel,option?.id || _vm.getOptionLabel(option))},on:{"input":function($event){return _vm.selectValue(option)},"change":function($event){_vm.vModel=option?.id || _vm.getOptionLabel(option)}}}),_c('span',{staticClass:"text-sm text-gray-500 ml-2"},[_vm._v(_vm._s(_vm.getOptionLabel(option)))])])])}),0)]:_c('div',{staticClass:"flex"},[_c('label',{staticClass:"max-w-xs flex block w-full items-center",class:{
        'cursor-not-allowed opacity-50': _vm.disabled,
        'cursor-pointer': !_vm.disabled,
      }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modelValue),expression:"modelValue"}],staticClass:"vst-radio__input transition-all shrink-0 !m-0 mt-0.5 border-gray-200 rounded-full",class:[`text-${_vm.color}-600`],attrs:{"name":_vm.data,"disabled":_vm.disabled,"type":"radio"},domProps:{"value":_vm.label,"checked":_vm._q(_vm.modelValue,_vm.label)},on:{"input":function($event){return _vm.$emit('input', _vm.value)},"change":function($event){_vm.modelValue=_vm.label}}}),_c('span',{staticClass:"text-sm text-gray-500 ml-2"},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.label))]})],2)])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }