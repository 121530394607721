<template lang="">
  <div ref="editor" />
</template>
  
<script>
import '@toast-ui/editor/dist/toastui-editor.css';
import Editor from '@toast-ui/editor';

export default {
  props: {
    short: { type: Boolean, default: false },
    id: { type: String, default: '' },
    meta: { type: Object, default() { return {}; } },
    input: { type: Object, default() { return {}; } },
    value: { type: String, default() { return ''; } },
  },
  data() {
    return {
      editor: null,
    };
  },
  mounted() {
    this.initEditor();
  },
  methods: {
    initEditor() {
      this.editor = new Editor({
        el: this.$refs.editor,
        placeholder: 'Будь ласка, введіть текст...',
        // eslint-disable-next-line no-unsafe-optional-chaining
        height: typeof +this.input?.height === 'number' && +this.input?.height
          ? `${this.input?.height}px`
          : '400px',
        events: {
          change: () => {
            this.$emit(
              'input',
              this.input?.html ? this.editor.getHTML() : this.editor.getMarkdown(),
            );
            console.log(   this.input?.html ? this.editor.getHTML() : this.editor.getMarkdown());
          },
          load: () => {
            this.$nextTick(() => {
              if (this.input?.html) {
                this.editor?.setHTML(this.value || '', false);
              }
              else {
                this.editor?.setMarkdown(this.value || '', false);
              }
              this.loading = false;
              this.editor.moveCursorToStart(!!this.input?.autofocus);
            });
          },
        },
        initialEditType: 'wysiwyg',
        previewStyle: 'vertical',
        hideModeSwitch: this.short,
        usageStatistics: false,
        autofocus: !!this.input?.autofocus,
        initialValue: '',
        toolbarItems: this.input.short
          ? [['heading', 'bold', 'italic', 'strike'], ['image', 'link'], ['code', 'codeblock']]
          : [
            ['heading', 'bold', 'italic', 'strike'],
            ['hr', 'quote'],
            ['ul', 'ol', 'task', 'indent', 'outdent'],
            ['table', 'image', 'link'],
            ['code', 'codeblock'],
            ['scrollSync'],
          ],
      });

      // прибираємо type='button' у кнопок, тому що стилі tw перебивають їх відображення
      this.removeButtonTypeAttribute();
      this.editor.getMarkdown();
      this.metaId = this.meta.id;
    },
    removeButtonTypeAttribute() {
      const buttons = document.querySelectorAll('.toastui-editor-toolbar-icons');
      buttons.forEach((button) => {
        button.removeAttribute('type');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
