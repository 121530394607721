<template>
  <div>
    <form>
      <label
        v-if="label"
        for="file-input"
        class="mb-2 text-gray-500 cursor-pointer"
      >{{ label }}</label>
      <input
        id="file-input"
        type="file"
        name="file-input"
        class="block w-full transition-all file:transition-all cursor-pointer hover:border-blue-500 file:cursor-pointer border border-gray-200 shadow-sm
        rounded-md focus:z-10 focus:border-blue-500 focus:ring-blue-500 py-3 px-3 file:mr-4 file:py-3 file:px-6 file:rounded-md
        file:border-0 file:font-semibold file:bg-blue-500 file:text-white hover:file:bg-blue-700"
        :class="{
          'text-xs file:text-xs': size === 'mini',
          'text-sm file:text-sm': size === 'default',
          'text-lg file:text-lg': size === 'large',
        }"
        @change="handleFileChange"
        @input="handleFileChange"
      >
    </form>
  </div>
</template>

<script>
export default {
  props: {
    maxFileSizeMB: {
      type: Number,
      default: 20, // Значення за замовчуванням: 2 MB
    },
    label: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: () => 'default',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleFileChange($event) {
      const file = $event.target.files[0];
      if (this.validateFileSize(file)) {
        this.$emit('change', $event);
        this.$emit('input', $event);
      } else {
        alert(
          `Недопустимий розмір файлу. Макс. розмір - ${this.maxFileSizeMB}MB`,
        );
      }
    },
    validateFileSize(file) {
      const maxSizeBytes = this.maxFileSizeMB * 1024 * 1024;
      return file.size <= maxSizeBytes;
    },
  },
};
</script>

<style lang="scss" scoped>
.vs-upload {
  &:focus {
    outline: 2px solid #2563eb;
  }
}
</style>
