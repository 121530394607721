const colorArray = ['#FF6633', '#FFB399', '#FF33FF', '#00B3E6',
  '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
  '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
  '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
  '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
  '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
  '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
  '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
  '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
  '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF', '#FFFF99'];

const styleDef = {};

/**
 * Функція призначена для завантаження іконки (значка) для відображуваних об'єктів на карті
 *
 * @type function
 * @summary Завантажує значок (pin)
 * @alias loadImage
 * @priority 3
 * @tag mixins
 * @param {Object} image Назва файлу значка
 * @example
 * const d = await this.loadImage(image);
 *
 */
const loadedImages = {};
async function loadImage(img = 'pin4-sm-mi-plumber+f45942.png', map = null) {
  if (loadedImages[img]) return null;
  return new Promise((resolve) => {
    map?.loadImage(img?.includes('/') ? img : `/api-user/marker_icon/${img}`, (err, image) => {
      if (err) {
        console.log(err);
      } else {
        map?.addImage(img, image, { sdf: img?.includes('+sdf') });
        loadedImages[img] = true;
      }
      resolve();
    });
  });
}
function rgbaToHex(color) {
  if (!color?.startsWith('rgba')) return color;
  // const color = 'rgba(249,6,6,1,0)';
  const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
  // eslint-disable-next-line no-bitwise
  const hex = `${((1 << 24) + (parseInt(rgba[0], 10) << 16) + (parseInt(rgba[1], 10) << 8) + parseInt(rgba[2], 10)).toString(16).slice(1)}`;
  return hex;
}
styleDef.point = async (layer = {}, style = {}, map = null) => {
  if (!style) {
    style = {};
  }

  if (style?.width?.[0] === '[') {
    style.width = { stops: JSON.parse(style.width) };
  }

  if (typeof style?.width === 'string') {
    style.width -= 0;
  }
  if (typeof style?.opacity === 'string') {
    style.opacity = (style.opacity - 0) * 100;
  }
  // icon
  const colorAttr = layer.style?.colorAttr || layer.style?.iconCls;
  let icon = {
    circle: 'none-xl-{{marker}}+222.png', circle2: 'circle-xl-{{marker}}+222.png', pin2: 'pin2-xl-{{marker}}+sdf.png', pin: 'pin-xl-{{marker}}+sdf.png',
  }[style.iconType] || style?.icon;
  const [pin, size] = icon?.split('-') || [];

  const color = layer.style?.color || 'blue';

  // icon marker
  if (!layer.style?.iconAttr && icon?.includes('{{marker}}')) {
    icon = icon.replace('{{marker}}', 'fa-star');
  }
  layer.legendAuto = { icon: [], color: [] };
  if (layer.style?.iconAttr && icon) {
    const col = layer.meta?.columns?.find((el) => el.name === layer?.style?.iconAttr);
    const val = col?.values || (await window.axios.get(`/api-user/gis-ir/${layer.id}/${layer.style?.iconAttr}`).then((el) => el.data.rows));

    if (val?.length) {
      const iconColor = icon.replace('{{color}}', color.length ? color : rgbaToHex(color) || [0]).split('+')[1].split(/[.-]/)[0];
      val.forEach((el, i) => el.icon = el.icon?.includes('png') ? el.icon : `${pin}-${size}-${el.data || el.icon || `ub-${i}`}+${icon?.includes('circle') ? 'ddd-222' : iconColor}.png`);

      icon = icon?.includes('{{') ? `${pin}-${size}-fa-question+0c53ad.png` : icon;
      // legend auto
      layer.legendAuto.iconText = col?.title || layer.style.iconAttr;
      val.forEach((el) => layer.legendAuto.icon.push(el));
      layer.legendAuto.icon.push({ icon, text: 'Не визначено' });

      layer.style.iconArr = ['match', ['get', layer.style.iconAttr]]
        .concat(val.filter((el) => el.id).reduce((p, el) => p.concat([el.id, el.icon]), [])).concat([icon]);

      await Promise.all(val.map((el) => el.icon).map((el) => loadImage(el, map)));
      await loadImage(icon, map);
    } else {
      icon = colorAttr ? 'pin6-m-fa-star+{{color}}.png' : `pin6-m-fa-star+${color?.replace('#', '')}.png`;
    }
  }

  // icon color
  // sdf change color to sdf
  if (icon?.includes('{{color}}')) {
    icon = icon.replace('{{color}}', '222'); //  'pin4-l-danger-svg+sdf.png';// 'pin6-l-mi-embassy+f03.png';//
  } else if (style?.icon && !style.iconAttr) {
    // style.color = style.color || `#${style.icon?.split('+')[1].replace('.png', '')}`;
    // icon = `${style.icon?.split('+')[0]}+sdf.png`;
  }
  if (colorAttr) {
    const col = layer.meta?.columns?.find((el) => el.name === colorAttr);
    const val = col?.values || (await window.axios.get(`/api-user/gis-ir/${layer.id}/${colorAttr}`).then((el) => el.data.rows));
    // icon = icon?.includes('sdf') || icon?.includes('circle') ? icon : `${icon.split('+')[0]}+sdf.png`;
    layer.style.color = val?.length ? ['match', ['get', colorAttr]].concat(val.filter((el) => el.id).reduce((p, el, i) => p.concat([el.id, el.color || colorArray[i]]), [])).concat(['#aaa']) : color;
    layer.style.colorArr = layer.style.color;
    // legend auto
    layer.legendAuto.colorText = col?.title || colorAttr;
    val.forEach((el) => layer.legendAuto.color.push(el));
    layer.legendAuto.color.push({ color: '#aaa', text: 'Не визначено' });

    /// icon default
    if (!layer.style?.iconAttr && !icon?.includes('+sdf') && icon) {
      val.forEach((el, i) => el.icon = `${icon?.split('+')[0]}+${(el.color || colorArray[i]).replace('#', '')}.png`);
      layer.style.iconArr = ['match', ['get', colorAttr]]
        .concat(val.filter((el) => el.id).reduce((p, el) => p.concat([el.id, el.icon]), [])).concat([icon]);
      await Promise.all(val.map((el) => el.icon).map((el) => loadImage(el, map)));
    }
    // console.log(layer.style.color);
  }
  /* if (layer.legendAuto?.color?.length || layer.legendAuto?.icon?.length) {
    console.log(layer.legendAuto);
  } */

  // loadImg
  if (icon && !icon?.includes('{')) {
    await loadImage(icon, map);
  }

  const iconSize = icon?.includes('sdf') ? ['interpolate', ['linear'], ['zoom'], 14, 0.1, 18, 0.2] : ['interpolate', ['linear'], ['zoom'], 14, 0.7, 16, 1];

  style.iconOffset = icon?.includes('pin') ? [0, icon?.includes('+sdf') ? -96 : -20] : null;

  return [
    {
      id: `${layer.id}`,
      source: layer.id,
      type: 'circle',
      cluster: true,
      lsource: style?.lsource,
      minzoom: style?.minzoom !== undefined ? +style?.minzoom : 4,
      maxzoom: (icon?.includes('+sdf') || (!icon?.includes('circle') && !icon?.includes('none')) ? +style.iconZoom : null) || +style?.maxzoom || 24,
      // maxzoom: 20,
      filter: ['all', ['==', ['geometry-type'], 'Point'], ['!', ['has', 'point_count']]],
      paint: {

        'circle-stroke-width': [
          'case',
          ['boolean', ['feature-state', 'select'], false],
          1,
          ['boolean', ['feature-state', 'hover'], false],
          2,
          1,
        ],
        'circle-stroke-color': [
          'case',
          ['boolean', ['feature-state', 'select'], false],
          style.color || style?.stroke || '#fff',
          ['boolean', ['feature-state', 'hover'], false],
          'maroon',
          style.color || style?.stroke || '#fff',
        ],
        'circle-opacity':
        (style.iconZoom ? [
          'interpolate',

          ['exponential', 0.5],
          ['zoom'],
          +style.iconZoom - 1,
          1,
          +style.iconZoom,
          (icon?.includes('none') ? 0.3 : 0),
        ] : null) || style.opacity || 1,
        'circle-color': style.color || [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          'red',
          'blue',
          // '#F79824',
        ],
        'circle-radius': (Array.isArray(style.width) ? { stops: style.width } : null) || style.width
          || {
            stops: style.iconZoom ? [[+style.iconZoom, 4], [+style.iconZoom, 8], [18, 16]] : [
              [13, 4],
              [15, 8],
              [18, 12],
            ],
          },
      },
    }]
    .concat(style.iconArr || style.icon ? [{
      id: `${layer.id}-5`,
      source: layer.id,
      lsource: style?.lsource,
      type: 'symbol',
      minzoom: +style.iconZoom || 16,
      filter: ['all', /* ['==', ['geometry-type'], 'Point'], */ ['!', ['has', 'point_count']]],
      layout: {

        'icon-image': style.iconArr || icon,

        'icon-allow-overlap': true,
        ...(style.iconOffset ? { 'icon-offset': style.iconOffset || false } : {}),
        ...(iconSize ? { 'icon-size': iconSize || false } : {}),
      },
      paint: {
        // ...(icon?.includes('rect') ? { 'icon-halo-blur': 10, 'icon-halo-color': '#fff', 'icon-halo-width': 10 } : {}),
        'icon-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.5, ['boolean', ['feature-state', 'select'], false], 0.5,
          (icon?.includes('+sdf') ? 1 : null) || (icon?.includes('circle') ? 0.6 : 1)],
        ...(icon?.includes('+sdf') ? { 'icon-color': ['case', ['boolean', ['feature-state', 'hover'], false], '#cd0bbf', ['boolean', ['feature-state', 'select'], false], '#8517cf', style.color || '#aa0000'] } : {}),
      },
    }] : [])
    .concat(style.label ? [{
      id: `${layer.id}-4`,
      source: layer.id,
      lsource: style?.lsource,
      type: 'symbol',
      minzoom: 14,
      filter: ['!', ['has', 'point_count']],
      layout: {
        'text-field': style.label,
        'text-font': ['Open Sans Regular'],
        'text-size': style['text-size'] || 8,
      },
      paint: {
        'text-color': style['text-color'] || '#ffffff',
        'text-halo-color': style['text-halo-color'] || 'black',
      },
    }] : [])
    .concat(layer.cluster || !!style.clusterMaxZoom
      ? [{
        id: `${layer.id}-1`,
        source: layer.id,
        lsource: style?.lsource,
        type: 'circle',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#51bbd6',
            100,
            '#f1f075',
            750,
            '#f28cb1',
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40,
          ],
        },
      },

      {

        id: `${layer.id}-2`,
        source: layer.id,
        lsource: style?.lsource,
        type: 'symbol',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['Open Sans Regular'],
          'text-size': 12,
        },
      }] : []);

  /* {
      id: `${layer.id}-4`,
      source: layer.id,
      type: 'symbol',
      filter: ['!', ['has', 'point_count']],
      layout: {
        'icon-image': 'cat', // reference the image
        'icon-size': 1,
        'icon-anchor': 'bottom',
      },
    }, */
};

styleDef.label = (layer = {}) => [{
  id: layer.id,
  source: layer.id,
  type: 'line',
  layout: {
    'text-field': '{str_name}',
    'text-font': [
      'DIN Offc Pro Italic',
      'Arial Unicode MS Regular',
    ],
    'symbol-placement': 'line',
    'text-pitch-alignment': 'viewport',
    'text-max-angle': 30,
    'text-size': {
      base: 1,
      stops: [
        [
          13,
          12,
        ],
        [
          18,
          16,
        ],
      ],
    },
  },
  paint: {
    'text-halo-width': 0,
    'text-halo-blur': 0,
    'text-color': '#78888a',
  },
}];

styleDef.polygon = async (layer = {}, style = {}, map = null) => {
  const { colorAttr } = style;
  const color = layer.style?.color || 'blue';
  if (style.colorAttr) {
    if (map.getSource(layer.id)) {
      map.getSource(map.getSource(layer.id).setData(`${map.getSource(layer.id)._data.split('&props')[0]}&props=${colorAttr}`));
    }
    const val = layer.meta?.columns.find((el) => el.name === colorAttr)?.values || (await window.axios.get(`/api-user/gis-ir/${layer.id}/${colorAttr}`).then((el) => el.data.rows));
    layer.legendAuto = { color: [] };
    val.forEach((el) => layer.legendAuto.color.push(el));
    layer.legendAuto.color.push({ color: '#aaa', text: 'Не визначено' });
    layer.style.color = val ? ['match', ['get', colorAttr]].concat(val.filter((el) => el.id).reduce((p, el, i) => p.concat([el.id, el.color || colorArray[i]]), [])).concat(['#aaa']) : color;
  }
  return [{
    id: `${layer.id}`,
    source: `${layer.id}`,
    lsource: style?.lsource,
    minzoom: (+style?.minzoom || +style?.pointZoom || 0) - 0,
    maxzoom: +style?.maxzoom || 24,
    type: 'fill',
    filter: ['all', ['match', ['geometry-type'], ['Polygon', 'MultiPolygon'], true, false]],
    paint: {
      'fill-color': ['case',
        ['boolean', ['feature-state', 'hover'], false],
        'red',
        ['boolean', ['feature-state', 'select'], false],
        '#c356c1',
        style?.color || '#0080ff'],
      'fill-opacity': 0.5,
    },
  }]
    .concat(style?.pointZoom ? [{
      id: `${layer.id}-5`,
      source: layer.url?.includes('.vmt') ? `${layer.id}` : `${layer.id}-point`,
      type: 'circle',
      minzoom: 0,
      maxzoom: (style?.pointZoom || 1) - 0,
      paint: {
        'circle-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          'red',
          style?.color || 'blue',
        ],
        'circle-stroke-width': 1,
        'circle-stroke-color': '#fff',
      },
    }] : [])
    .concat(style?.label ? [{
      id: `${layer.id}-1`,
      source: layer.id,
      type: 'symbol',
      lsource: style?.lsource,
      minzoom: 15,
      layout: {
        'text-field': style?.label,
        'text-font': ['Open Sans Regular'],
        'text-size': style['text-size'] || { stops: [[14, 4], [18, 16]] },
      },
      paint: {
        'text-color': style['text-color'] || '#ffffff',
        'text-halo-color': style['text-halo-color'] || '#fff',
        'text-halo-width': style['text-halo-width'] || 0,
      },
    }] : [])
    .concat(style?.stroke ? [{
      id: `${layer.id}-2`,
      source: layer.id,
      lsource: style?.lsource,
      minzoom: (+style?.minzoom || +style?.pointZoom || 0) - 0,
      maxzoom: +style?.maxzoom || 24,
      type: 'line',
      paint: {
        'line-color': ['case',
          ['boolean', ['feature-state', 'select'], false],
          '#c356c1',
          style.stroke],
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'select'], false],
          3,
          (style.width || style['line-width'] || 1) - 0,
        ],
      },
    }] : []);
};

styleDef.line = (layer = {}, style = {}) => [{
  id: layer.id,
  source: layer.id,
  lsource: style.lsource,
  type: 'line',
  filter1: ['all', ['match', ['geometry-type'], ['LineString', 'MultiLineString', 'MultiPolygon', 'Polygon'], true, false]],
  minzoom: style?.minzoom || layer.minzoom || 5,
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-width': [
      'case',
      ['boolean', ['feature-state', 'select'], false],
      4,
      style.width || style['line-width'] || 1],
    'line-color': [
      'case',
      ['boolean', ['feature-state', 'select'], false],
      '#c356c1',
      style.color || style['line-color'] || '#c71ccb',
    ] /* 'hsl(185, 12%, 89%)'* */,
    'line-gap-width': style['line-gap-width'] || 0 /* || {
        base: 1.5,
        stops: [
          [
            14,
            0.5,
          ],
          [
            18,
            12,
          ],
        ],
      } */,
    /* 'line-dasharray': [
        3,
        3,
      ], */
  },
}].concat(style.label ? [{
  id: `${layer.id}-4`,
  source: layer.id,
  lsource: style.lsource,
  type: 'symbol',
  minzoom: 14,
  paint: {
    'text-color': '#111',
    'text-halo-color': '#fff',
    'text-halo-width': 2,
  },
  layout: {
    'symbol-placement': 'line',
    'text-letter-spacing': 0.2,
    'text-field': style.label,
    'text-font': ['Open Sans Regular'],
    'text-size': style['text-size'] || 15,

  },
}] : []);

styleDef.linestring = styleDef.line;
styleDef.circle = styleDef.point;

export async function getVectorStyle(id, layer, map) {
  // YAML, JSON parse
  if (typeof layer.style === 'string') {
    layer.style = layer.style[0] === '{' ? JSON.parse(layer.style) : window.YAML.parse(layer.style)?.style;
  }
  // vector tile add source
  layer.style?.filter?.((el) => el.source).forEach((el) => {
    el.lsource = `${layer.id}-${el.source}`;
  });

  // type detect
  const type = layer?.style?.type || (layer?.meta?.geom?.geometry?.length > 2 ? ['point'] : [(layer?.meta?.geom?.geometry?.[0].toLowerCase() || 'point')])?.map((el) => el?.replace?.('multi', ''));
  const typeAr = (Array.isArray(type) ? type : [type]);

  const style = (await Promise.all(Array.isArray(layer?.style) ? layer.style.map(async (el) => styleDef[el.type]?.(layer, el, map)) : typeAr.map(async (tp) => styleDef[tp]?.(layer, layer.style, map))))
    .reduce((p, el) => (el ? p.concat(el) : p), []);

  style.forEach((el, i) => {
    el.id = `${el.id}-${i}`;
  });

  const sl = style;
  if (layer.service === 'vtile' || layer.url?.includes('.vmt')) {
    sl.forEach((el) => {
      if (!el.layout) el.layout = {};
      el.layout.visibility = 'visible';

      el['source-layer'] = el.lsource || layer.data || id;
    });
  }

  return sl;
}

export function randomColor() {
  const s = [Math.floor(Math.random() * 255), Math.floor(Math.random() * 255), Math.floor(Math.random() * 255)];
  return `rgb(${s.join(',')})`;
}
