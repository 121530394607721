<template>
  <div :id="id" :style="'height:'+(input.height||300)+'px'" class="form-codeflask">
  </div>
</template>

<script>
import head from '@/vendor/head.js'

export default {
  props: {
    value: { type: String, default() { return ''; } },
    input: { type: Object, default() { return {}; } },
  },
  data() {
    return {
      id: `ace${(Math.random() * 100000).toFixed(0)}`,
      editor: null,
      beautify: null,
      editorInstance: null,
    };
  },
  watch: {
    value() {
        if (!this.editor || this.value === this.editor?.getValue()) return;
        this.$emit('update:value', this.value);
      
        this.editor.setSession(window.ace.createEditSession(this.value));
        this.editor.session.setMode(`ace/mode/${this.input.syntax}`);
      
        this.editor.session.setOption('useWorker', false);
      
        this.editor.session.setOption('showGutter', false);

        this.editor.getSession().on('change', () => {
        this.$emit('input', this.editor.getValue());
      });
    },
  },
  async mounted() {
    if (!window.Ace) {
      this.loadLib();
    } else {
      this.initEditor();
    }
  },
  methods: {
    async loadLib() {
      await head.promise(['https://cdnjs.cloudflare.com/ajax/libs/ace/1.12.3/ace.js']);
      this.initEditor();
    },
    initEditor() {
      if (!document.getElementById(this.id)) {
        setTimeout(() => this.initEditor(), 100);
        return;
      }
      this.editor = window.ace.edit(this.id, {
        mode: 'ace/mode/javascript',
        showGutter: false,
        showLineNumbers: true,
      });


      this.editor.setSession(window.ace.createEditSession(typeof this.value === 'string' ? this.value : JSON.stringify(this.value, null, 2)));
      this.editor.session.setMode(`ace/mode/${this.input.syntax}`);
      this.editor.session.setOption('showGutter', false);
      this.editor.getSession().on('change', () => {
        this.$emit('input', this.editor.getValue());
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .form-codeflask{
    position: relative;
    width: 100%;
  }
.codeflask{
  pre {
    color: #000;
    background-color: transparent;
    border: unset;
    border-radius:unset;
  }
  &__textarea{
    border:1px solid #eee;
  }
}
</style>
