<!-- eslint-disable -->
<template>
  <div class="VstCheckbox">
    <template v-if="!checkboxBody && !options.length">
      <div ref="checkbox">
        <input
          :id="inputId"
          v-model="modelValue"
          :value="label"
          :class="checkboxClasses"
          type="checkbox"
          :disabled="disabled"
          @change="$emit('change', $event.target.checked)"
        />
        <label
          @click.stop
          v-if="label"
          :for="inputId"
          :class="[
            'vs-checkbox-label text-gray-500 font-normal mb-0 ml-3',
            { 'text-xs': size === 'xs' },
            { 'text-sm': size === 'default' },
            { 'text-md': size === 'md' },
            { 'text-lg': size === 'lg' },
            { 'text-xl': size === 'xl' },
            { 'cursor-pointer': !disabled },
            { 'cursor-not-allowed': disabled },
          ]"
        >
          <slot>{{ label }}</slot>
        </label>
      </div>
    </template>
    <template v-if="options.length">
      <div class="flex flex-col gap-2">
        <div v-for="option in options" :key="formatOption(option)" class="flex items-center">
          <input
            :id="inputId + '-' + formatOption(option)"
            v-model="selectedOptionsInternal"
            :class="checkboxClasses"
            type="checkbox"
            :disabled="disabled"
            :value="formatOption(option)"
            @change="toggleCheckbox($event)"
            @input="toggleCheckbox($event)"
          />
          <label
            :for="inputId + '-' + formatOption(option)"
            class="vs-checkbox-label text-gray-500 ml-3 font-normal mb-0"
            :class="[
              { 'cursor-pointer': !disabled },
              { 'cursor-not-allowed': disabled }
            ]"
          >
            {{ formatOption(option) }}
          </label>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    value: { type: [Array, Boolean, String], default: () => [] },
    options: { type: Array, default: () => [] },
    size: { type: String, default: "default" },
    label: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    color: { type: String, default: "blue" },
  },
  data() {
    return {
      selectedOptionsInternal: [],
      inputId: `checkbox-${Math.random().toString(36).substring(7)}`,
    };
  },
  computed: {
    checkboxBody() {
      const body = this.$parent?.group;

      return Boolean(body);
    },
    modelValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    checkboxClasses() {
      return [
        "mt-0 shrink-0 outline-transparent transition-all border-gray-200 rounded",
        { "cursor-not-allowed": this.disabled, "cursor-pointer": !this.disabled },
        { "w-4 h-4 text-xs": this.size === "xs" },
        { "w-4.5 h-4.5 text-sm": this.size === "sm" },
        { "w-5 h-5 text-md": this.size === "md" },
        { "w-6 h-6 text-lg": this.size === "lg" },
        { "w-7 h-7 text-xl": this.size === "xl" },
        { "mt-0": this.options },
        { "!m-0": !this.options },
        `text-${this.color}-600`,
        `focus:ring-${this.color}-500`,
        `checked:border-${this.color}-500`,
      ];
    },
    labelClasses() {
      return [
        "vs-checkbox-label text-gray-500 cursor-pointer font-normal mb-0 ml-3",
        { "text-xs": this.size === "xs" },
        { "text-sm": this.size === "default" },
        { "text-md": this.size === "md" },
        { "text-lg": this.size === "lg" },
        { "text-xl": this.size === "xl" },
      ];
    },
  },
  mounted() {
    const value = this.value === undefined && this.options.length ? [] : this.value;

    this.selectedOptionsInternal = value;
  },
  methods: {
    toggleCheckbox($event) {
      if (!this.options.length) {
        this.$emit("input", $event.target.checked);
        this.$emit("change", $event.target.checked);
      } else {
        this.$emit("input", this.selectedOptionsInternal);
        this.$emit("change", this.selectedOptionsInternal);
      }
    },
    formatOption(option) {
      return option.text || option.label || option.title || option;
    },
  },
};
</script>
<style lang="scss" scoped>
input {
  outline: none !important;
}
</style>
