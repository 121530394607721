import { render, staticRenderFns } from "./vs-form-datepicker.vue?vue&type=template&id=ebdedb8e&scoped=true"
import script from "./vs-form-datepicker.vue?vue&type=script&lang=js"
export * from "./vs-form-datepicker.vue?vue&type=script&lang=js"
import style0 from "./vs-form-datepicker.vue?vue&type=style&index=0&id=ebdedb8e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ebdedb8e",
  null
  
)

export default component.exports