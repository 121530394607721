<template>
  <div ref="wrapper">
    <button
      ref="button"
      class="transition-all py-2 px-3 inline-flex items-center gap-2 text-sm hover:text-gray-700 font-medium rounded-md hover:hover:text-blue-600"
      :class="{
        [$parent.value === name
          ? 'bg-white text-blue-500'
          : 'bg-transparent text-gray-500']: true, ['opacity-40 cursor-not-allowed']: disabled
      }"
      @click="handleClick"
    >
      {{ name }}
    </button>
    <div
      v-show="$parent.value === name"
      ref="body"
      class="h-full"
    >
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    name: String,
    disabled: Boolean,
  },
  data() {
    return {};
  },
  mounted() {
    const { button } = this.$refs;

    const parent = this.$parent;

    const { body } = this.$refs;

    parent.$refs.nav.append(button);
    parent.$refs.body.append(body);
    this.$refs.wrapper.remove();
  },
  methods: {
    handleClick() {
      if (this.disabled) return;

      this.$parent.value = this.name;
    },
  },
};
</script>
<style lang="scss"></style>
