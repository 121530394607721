<template>
  <div
    ref="wrapper"
    class="flex flex-col formAdmin"
  >
    <div class="sticky top-0 z-10 bg-white flex items-center justify-between py-4 mb-4 border-b">
      <div class="text-xl font-semibold">
        Додати
      </div>
      <div class="flex gap-2">
        <button
          type="button"
          class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-gray-200
          text-gray-500 hover:border-blue-600 hover:text-blue-600 disabled:opacity-50 disabled:pointer-events-none"
          @click="cancelForm()"
        >
          Відмінити
        </button>
        <button
          type="button"
          class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600
          text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
          @click="handleSubmit"
        >
          Зберегти
        </button>
      </div>
    </div>
    <VsForm
      v-model="formValues"
      :scheme="scheme"
      :token="token"
      :api="api"
    />
  </div>
</template>

<script>
import axios from 'axios';
import VsForm from '../vs-form.vue';

export default {
  components: {
    VsForm,
  },
  props: {
    scheme: { type: Array, default: () => null },
    api: { type: String, default: () => '' },
    form: { type: String, default: () => '' },
    data: { type: Object, default: () => null },
    host: { type: String, default: () => '' },
    token: { type: String, default: () => '' },
  },
  data() {
    return {
      formValues: this.data
    }
  },
  computed: {
    isAdd() {
      const url = window.location.href;
      return url.includes('add');
    },
  },
  methods: {
    cancelForm() {
      window.history.back();
      this.$parent.visible = false;
      this.$emit('close');
      this.$emit('cancel');
    },
    async getNextId() {
      try {
        const resp = await fetch('/api/next-id');
        if (resp.ok) {
          const data = await resp.json();
          return data.id;
        }
      } catch (error) {
        console.error(error);
        this.$vsNotify({ type: 'error', message: 'Помилка при створенні ідентифікатора', title: 'Помилка!' });
      }
    },
    async handleSubmit() {
      try {
        const secondParam = this.isAdd ? await this.getNextId() : this.token;

        const resp = await axios({
          url:`/api/${this.token}/${secondParam}`,
          method: this.isAdd?'POST':'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          data: this.formValues
        });

        if (resp.status===200) {
          //history.go(-1);
          this.$vsNotify({ type: 'success', message: 'Форму успішно збережено!', title: 'Успіх!' });
        }
      } catch (error) {
        console.error(error);
        this.$vsNotify({ type: 'error', message: 'Помилка при відправленні форми', title: 'Помилка!' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
:root {
  --formHeight: 0px;
}

.formAdmin {
  padding: 15px;
  background: #fff;
}
</style>
