<template lang="">
  <div>
    <div class="bg-white border rounded-md" :class="{ 'border-gray-200': !error, 'border-red-500': error }">
      <div class="w-full flex justify-between gap-x-1">
        <div class="grow">
          <input
            ref="inputNumber"
            v-model="currentVal"
            type="text"
            class="vs-form-number w-full bg-transparent border-none text-gray-800 focus:ring-0 py-2 px-3"
            :disabled="disabled"
            :class="inputClases"
            @blur="handleBlur"
            @keydown="handleKeyDown"
          />
        </div>
        <div class="flex items-center -gap-y-px divide-x divide-gray-200 border-s border-gray-200">
          <button
            type="button"
            class="w-10 h-full inline-flex justify-center items-center gap-x-2 font-medium last:rounded-e-lg bg-white text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
            :class="inputClases"
            @click="handleClick('minus')"
          >
            <svg
              class="flex-shrink-0 w-3.5 h-3.5"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M5 12h14" />
            </svg>
          </button>
          <button
            type="button"
            class="w-10 h-full inline-flex justify-center items-center gap-x-2 font-medium last:rounded-e-lg bg-white text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
            :class="inputClases"
            @click="handleClick('plus')"
          >
            <svg
              class="flex-shrink-0 w-3.5 h-3.5"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M5 12h14" />
              <path d="M12 5v14" />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <p v-if="errorMessage" class="text-sm text-red-600 mt-2">
      {{ errorMessage }}
    </p>
  </div>
</template>
<script>
import Decimal from 'decimal.js';
import filterElementMx from '@/components/form/mixins/vs-form-mx';

export default {
  mixins: [filterElementMx],
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    min: {
      type: [Number, String],
      default: -Infinity,
    },
    max: {
      type: [Number, String],
      default: Infinity,
    },
    step: {
      type: [Number, String],
      default: 1,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentVal: 0,
    };
  },
  computed: {
    inputClases() {
      return [{ [`!text-${this.size}`]: this.size !== 'md' }, { 'text-base': this.size === 'md' }];
    },
  },
  watch: {
    currentVal(n) {
      this.$emit('change', n);
    },
  },
  mounted() {
    this.currentVal = this.value;
    if (this.autofocus) {
      this.$refs?.inputNumber?.focus();
    }
  },
  methods: {
    handleClick(operation) {
      if (this.disabled) return;

      switch (operation) {
        case 'plus': {
          const increasedValue = new Decimal(+this.currentVal).plus(+this.step);
          this.currentVal = Math.min(increasedValue, +this.max);
          break;
        }
        case 'minus': {
          const decreasedValue = new Decimal(+this.currentVal).minus(+this.step);
          this.currentVal = Math.max(decreasedValue, +this.min);
          break;
        }
        default:
          break;
      }
    },
    handleBlur() {
      this.validateNumber();
    },
    handleKeyDown(event) {
      if (event.key === 'Escape') {
        this.$refs?.inputNumber.blur();
      }
    },
    validateNumber() {
      const inputValue = this.currentVal;
      let parsedValue = parseFloat(inputValue);
      console.log(parsedValue);
      if (isNaN(parsedValue)) {
        this.currentVal = 0;
        return;
      }
      parsedValue = Math.min(Math.max(parsedValue, +this.min), +this.max);
    },
  },
};
</script>
<style lang="scss">
.vs-form-number {
  border: none !important;
}
</style>
