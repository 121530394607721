var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fieldset',{staticClass:"flex flex-wrap gap-3"},_vm._l((_vm.dynamicColors),function(color){return _c('div',{key:color},[_c('input',{staticClass:"peer hidden",attrs:{"id":color,"type":"radio","name":color,"disabled":_vm.disabled},domProps:{"value":color,"checked":_vm.selectedColor === color},on:{"change":function($event){return _vm.updateSelectedColor($event)}}}),_c('label',{staticClass:"transition-all block rounded-full shadow-sm peer-checked:ring-2 peer-checked:ring-offset-2",class:[
        'bg-' + color + '-500',
        'peer-checked:ring-' + color + '-500',
        _vm.disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer',
        { 'h-3 w-3': _vm.size === 'xs' },
        { 'h-5 w-5': _vm.size === 'sm' },
        { 'h-8 w-8': _vm.size === 'md' },
        { 'h-10 w-10': _vm.size === 'lg' },
        { 'h-12 w-12': _vm.size === 'xl' },
      ],attrs:{"for":color}})])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }