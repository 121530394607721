export default {
  props: {
    size: { type: String, default: 'sm' },
    id: { type: String, default: '...' },
    name: { type: String, default: '...' },
    options: { type: Array, default: () => [] },
    // default_option: { type: String, default: "Оберіть значення" },
    color: { type: String, default: 'blue' },
    icon: { type: String, default: '' },
    clearable: { type: Boolean, default: true },
    multiple: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    errorMessage: { type: String, default: '' },
    error: { type: Boolean, default: false },
    label: { type: String, default: '' },
  },
};
