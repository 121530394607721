var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex items-center"},[(_vm.inactiveText.length)?_c('span',{class:[
        `mr-3 text-${_vm.size} font-medium`,
        { [`text-${_vm.color}-500`]: !_vm.value },
        [_vm.disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'],
      ],on:{"click":function($event){return _vm.toggleSwitch(!_vm.value)}}},[_vm._v(" "+_vm._s(_vm.inactiveText)+" ")]):_vm._e(),(_vm.inactiveIcon)?_c('span',{class:[
        `mr-3 text-${_vm.size} font-medium`,
        { [`text-${_vm.color}-500`]: !_vm.value },
        [_vm.disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'],
      ],on:{"click":function($event){return _vm.toggleSwitch(!_vm.value)}}},[_c('i',{class:_vm.inactiveIcon})]):_vm._e(),_c('label',{staticClass:"relative inline-flex items-center",class:[_vm.disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer']},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modelValue),expression:"modelValue"}],class:_vm.switchClasses,attrs:{"id":"switchId","type":"checkbox","disabled":_vm.disabled},domProps:{"checked":Array.isArray(_vm.modelValue)?_vm._i(_vm.modelValue,null)>-1:(_vm.modelValue)},on:{"change":function($event){var $$a=_vm.modelValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.modelValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.modelValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.modelValue=$$c}}}})]),(_vm.label)?_c('label',{staticClass:"text-gray-500 ms-3",class:[`text-${_vm.size}`, [_vm.disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer']],style:({ fontWeight: 'normal' }),attrs:{"for":"switchId"}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),(_vm.activeText.length)?_c('span',{class:[
        `ml-3 text-${_vm.size} font-medium cursor-pointer`,
        { [`text-${_vm.color}-500`]: _vm.value },
        [_vm.disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'],
      ],on:{"click":function($event){return _vm.toggleSwitch(!_vm.value)}}},[_vm._v(" "+_vm._s(_vm.activeText)+" ")]):_vm._e(),(_vm.activeIcon)?_c('span',{class:([`ml-3 text-${_vm.size} font-medium`, { [`text-${_vm.color}-500`]: _vm.value }],
        [_vm.disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer']),on:{"click":function($event){return _vm.toggleSwitch(!_vm.value)}}},[_c('i',{class:_vm.activeIcon})]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }