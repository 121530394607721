<template>
  <div class="filezone">
    <div
      class="filezone__wrapper"
      :class="status"
      @click="handleClick"
      @dragover="dragOver"
      @dragleave="dragLeave"
      @drop="onDrop"
    >
      <template v-if="files.length">
        <div class="filezone__list">
          <div
            v-for="item in files"
            :key="item.name"
            data-testid="file"
            class="filezone__item d-flex align-items-center justify-content-between"
          >
            <div
              class="filezone__loader"
              :style="{
                width: item.loading + '%',
                'background-color': item.loading === 100 ? '#22c55e' : 'rgb(0 105 209)',
              }"
            />
            <div class="filezone__title m-r-sm m-l-sm">
              {{ item.name }}
            </div>
            <div class="filezone__size m-r-sm">
              {{ sizeFormat(item.size) + " МБ" }}
            </div>
            <div
              class="filezone__delete"
              @click.stop="handleDelete(item)"
            >
              <i class="ti ti-x" />
            </div>
          </div>
        </div>
        <VstButton
          data-testid="submit"
          :disabled="disabled"
          style="width: 100%; margin-top: auto"
          @click.stop="handleSubmit"
        >
          {{ buttonText }}
        </VstButton>
      </template>

      <div
        v-else
        class="filezone__empty"
      >
        <i class="ti ti-file-import" />
        <div>Додайте файл або перетягніть файл</div>
      </div>
    </div>
    <input
      ref="file"
      data-testid="input"
      class="filezone__input"
      :multiple="multiple"
      type="file"
      @change="handleFileChange"
    >
  </div>
</template>

<script>
import VstButton from './vs-button.vue';

export default {
  components: { VstButton },
  props: {
    buttonText: { type: String, default: () => 'Відправити' },
    api: { type: String, default: () => '' },
    maxFileSizeMB: { type: Number, default: 0 },
    multiple: { type: Boolean, default: () => false },
  },
  data() {
    return {
      status: '',
      disabled: false,
      files: [],
    };
  },
  methods: {
    handleClick() {
      if (this.disabled) return;
      this.$refs.file.click();
    },
    async handleSubmit() {
      if (this.api) {
        const files = await this.sendOnApi();
        this.disabled = false;
        await this.$emit('change', files);
      } else {
        this.$emit('change', this.files);
      }
    },
    onDrop($event) {
      $event.preventDefault();
      this.handleFileChange($event);
    },
    dragOver(e) {
      e.preventDefault();
      this.status = '';
    },
    dragLeave(e) {
      e.preventDefault();
      this.status = '';
    },
    handleFileChange($event) {
      $event.preventDefault();

      const eventFiles = $event?.dataTransfer?.files || $event.target.files;
      if (!eventFiles.length) return;

      const fileArray = Array.from(eventFiles);
      const validatedFiles = fileArray.filter((file) => this.validateFileSize(file));

      const unvalidatedFiles = fileArray.filter((file) => !this.validateFileSize(file));

      unvalidatedFiles?.forEach((file) => {
        this.$notify({
          message: `Недопустимий розмір файлу '${file.name}'`,
          type: 'error',
        });
      });

      const filesFromList = validatedFiles.reduce((prev, el) => {
        if (!this.files.find((file) => file.name === el.name)) {
          el.loading = 0;
          return prev.concat(el);
        }
        this.$message({
          type: 'warning',
          message: `Файл з назвою ${el.name} вже завантажений!`,
        });

        return prev;
      }, []);

      if (this.multiple) {
        this.files = this.files.concat(filesFromList);
      } else {
        this.files = filesFromList;
      }
    },
    sizeFormat(size) {
      return (size / 1024 / 1024).toFixed(3);
    },
    handleDelete(item) {
      this.files = this.files.filter((file) => file.name !== item.name);
    },
    async sendOnApi() {
      this.disabled = true;
      const filesData = await Promise.all(
        this.files
          .filter((el) => el.loading !== 100)
          .map(async (file) => {
            const formData = new FormData();
            formData.append('file', file);

            let data = await window.axios.post(this.api, formData, {
              onUploadProgress: (progressEvent) => {
                this.files = this.files.map((el) => {
                  if (el.name === file.name) {
                    el.loading = (progressEvent.loaded * 100) / progressEvent.total;
                  }

                  return el;
                });
              },
            });

            data = { ...data, fileData: file };

            return data;
          }),
      );

      return filesData;
    },
    validateFileSize(file) {
      if (!this.maxFileSizeMB) return true;

      const maxSizeBytes = this.maxFileSizeMB * 1024 * 1024;
      return file.size <= maxSizeBytes;
    },
  },
};
</script>

<style lang="scss" scoped>
.filezone {
  &__loader {
    top: 0;
    left: 0;
    height: 100%;
    z-index: 0;
    position: absolute;
    border-radius: 5px;
    background-color: rgb(0 105 209);
    transition: width 0.3s;
  }

  &__list {
    max-height: 195px;
    padding-right: 5px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #1989fa;
    }
    &::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }
  }

  &__item {
    position: relative;
    color: white;
    border-radius: 5px;
    background-color: #1989fa;
    width: 100%;
    white-space: nowrap;
    padding: 5px 10px;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__title {
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__delete {
    cursor: pointer;
    &:hover {
      color: red;
    }
  }

  &__title,
  &__delete,
  &__size {
    z-index: 1;
  }

  &__input {
    opacity: 0;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    height: 260px;
    border: 2px dashed #1989fa;
  }

  &__empty {
    margin: auto;
    cursor: pointer;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #999;
    text-align: center;
    justify-content: center;
    transition: all 0.3s;

    &:hover {
      color: #1989fa;
    }

    i {
      font-size: 48px;
      margin-bottom: 10px;
    }

    div {
      font-size: 14px;
    }
  }
}
</style>
