export default {
  data() {
    return {};
  },
  methods: {
    fileDownload(fileData, fileName, format = 'json') {
      const a = document.createElement('a');
      document.body.appendChild(a);
      const file = new File([fileData], `${fileName}.${format}`, {
        type: 'text/plain',
      });
      const url = window.URL.createObjectURL(file);
      a.href = url;
      a.download = `${fileName}.${format}`;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    },
    readFile(data, onLoad) {
      const file = data;

      const reader = new FileReader();

      reader.readAsText(file);

      reader.onload = () => onLoad(reader.result);

      reader.onerror = () => {
        console.log(reader.error);
      };
    },
  },
};
