<template>
  <div>
    <template v-if="options.length">
      <fieldset
        v-if="inputStyle === 'borderless'"
        :id="id"
        class="vs-form-checkbox"
        :class="[orientation === 'vertical' ? 'flex-col' : 'flex-row', 'flex gap-1']"
      >
        <div v-for="option in options" :key="option" class="flex items-center p-1">
          <input
            :id="getOptionLabel(option) + id"
            v-model="vModel"
            :name="id"
            :value="option?.id || getOptionLabel(option)"
            :disabled="disabled"
            type="radio"
            class="vst-radio__input transition-all shrink-0 !m-0 mt-0.5 border-gray-200 rounded-full"
            :class="[`text-${color}-600`]"
            @input="selectValue(option)"
          />
          <label
            :for="getOptionLabel(option) + id"
            class="transition-all text-gray-500 ml-2 mb-0 font-normal"
            :class="{
              'text-xs': size === 'mini',
              'text-sm': size === 'default',
              'text-lg': size === 'large',
              'cursor-not-allowed opacity-50': disabled,
              'cursor-pointer': !disabled,
            }"
          >{{ getOptionLabel(option) }}</label>
        </div>
      </fieldset>
      <div v-else :id="id" :class="[orientation === 'vertical' ? 'flex-col' : 'flex-row', 'flex gap-1']">
        <div v-for="option in options" :key="option" class="flex items-center p-1">
          <label
            class="max-w-xs flex p-3 block w-full bg-white border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500"
            :class="{
              'cursor-not-allowed opacity-50': disabled,
              'cursor-pointer': !disabled,
            }"
          >
            <input
              v-model="vModel"
              :name="id"
              :value="option?.id || getOptionLabel(option)"
              :disabled="disabled"
              type="radio"
              class="vst-radio__input transition-all shrink-0 !m-0 mt-0.5 border-gray-200 rounded-full"
              :class="[`text-${color}-600`]"
              @input="selectValue(option)"
            />
            <span class="text-sm text-gray-500 ml-2">{{ getOptionLabel(option) }}</span>
          </label>
        </div>
      </div>
    </template>
    <div v-else class="flex">
      <label
        class="max-w-xs flex block w-full items-center"
        :class="{
          'cursor-not-allowed opacity-50': disabled,
          'cursor-pointer': !disabled,
        }"
      >
        <input
          v-model="modelValue"
          :name="data"
          :value="label"
          :disabled="disabled"
          type="radio"
          class="vst-radio__input transition-all shrink-0 !m-0 mt-0.5 border-gray-200 rounded-full"
          :class="[`text-${color}-600`]"
          @input="$emit('input', value)"
        />
        <span class="text-sm text-gray-500 ml-2"><slot>{{ label }}</slot></span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    orientation: {
      type: String,
      default: "inline", // vertical, inline
    },
    inputStyle: {
      type: String,
      default: "borderless", // borderless, border
    },
    value: {
      type: String,
      default: "",
    },
    data: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: () => "default",
    },
    color: {
      type: String,
      default: () => "blue",
    },
  },
  data() {
    return {
      id: Math.floor(Math.random() * 1000),
      selectedValue: null,
    };
  },
  computed: {
    vModel() {
      return this.value.id || this.value.value || this.value;
    },
    modelValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    selectValue(option) {
      const hasObjOptions = this.options.some((el) => typeof el === "object");
      if (hasObjOptions) {
        this.selectedValue = option.id || option.value;
      } else {
        this.selectedValue = option;
      }
      this.$emit("input", this.selectedValue);
      this.$emit("change", this.selectedValue);
    },
    getOptionLabel(option) {
      return option?.text || option?.label || option?.title || option;
    },
    getOptionValue(value) {
      return value.id || value.value || value;
    },
  },
};
</script>

<style scoped>
.vst-radio__input:focus {
  outline: none;
}
</style>
