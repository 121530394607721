<template>
  <button
    ref="option"
    class=" select-option text-gray-900 group flex w-full items-center rounded-md px-2 py-2 text-sm cursor-pointer hover:bg-gray-100"
    @click.capture.stop="handleClick"
  >
    <slot>
      {{ label }}
    </slot>
  </button>
</template>
<script>
export default {
  props: {
    label: String,
    value: [String, Number, Boolean],
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    handleClick() {
      if (this.$parent.name === 'commander') {
        this.$parent.$emit('command', this.value);
      }

      const parentBtn = this.$parent.$refs.dropdownBtn;

      if (parentBtn) {
        parentBtn.click();
      }

      this.$emit('click');
      if (this.$parent.multiple) {
        const parentValue = this.$parent.value || [];

        if (!parentValue.includes(this.value)) {
          this.$parent.value = parentValue.concat(this.value);
        } else {
          this.$parent.value = this.$parent.value.filter(
            (el) => el !== this.value,
          );
        }

        if (
          !this.$parent.selectedValues.find((obj) => obj.value === this.value)
        ) {
          this.$parent.selectedValues = [
            ...this.$parent.selectedValues,
            { value: this.value, label: this.label },
          ];
        } else {
          this.$parent.selectedValues = this.$parent.selectedValues.filter(
            (el) => el.value !== this.value,
          );
        }
      } else {
        this.$parent.isDropdownOpen = false;
        this.$parent.value = this.value;
        this.$parent.selectedLabel = this.label;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.select-option{
  overflow-x: hidden;
  text-overflow: ellipsis;
}
</style>
