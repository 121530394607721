const alertHeader = 
            `<div slot="header" class="relative pt-4">
                <svg class="mx-auto mb-4 text-gray-400 w-12 h-12" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                </svg>
                <button type="button" class="absolute flex justify-center items-center w-7 h-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 top-4 end-4" @click="handleClose" style="right: 1rem">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="flex-shrink-0 w-4 h-4"><path d="M18 6 6 18"></path><path d="m6 6 12 12"></path></svg>
                  <span class="sr-only">Close modal</span>
                </button>
            </div>`;
const confirmHeader = 
            `<div slot="header" class="relative pt-4">
            <svg class="mx-auto mb-4 text-green-500 w-12 h-12 border border-green-500 p-2 rounded-full" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" style="border-width: 5px">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M5 13l4 4L19 7"></path>
            </svg>
            <button type="button" class="absolute flex justify-center items-center w-7 h-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 top-4 end-4" @click="handleClose" style="right: 1rem">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="flex-shrink-0 w-4 h-4"><path d="M18 6 6 18"></path><path d="m6 6 12 12"></path></svg>
            <span class="sr-only">Close modal</span>
          </button>
            </div>`;
const confirmOrAlertFooter = 
            `<div class="flex justify-end items-center pb-3 px-4" slot="footer">
              <button type="button" class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50" @click="handleClose">
                Ок
              </button>
            </div>`;

export {alertHeader, confirmHeader, confirmOrAlertFooter}