<template>
  <div class="flex items-center">
    <div class="mr-1">
      <VsButton title="Імпорт геометрії" color="black" icon="ti-file-import" square @click="dialogVisible = true" />
    </div>
    <div>
      <VsButton
        slot="reference"
        title="Експорт геометрії"
        icon="ti-file-export"
        color="black"
        square
        :disabled="aviableFeatures"
        @click="exportFile"
      />
    </div>
    <VsDialog :visible.sync="dialogVisible" title="Імпорт геометрії">
      <div class="p-2">
        <div class="mb-4">
          <div class="mb-3">
            <div class="mb-1">Система координат</div>
            <VsFormSelect
              v-if="sridOptions.length"
              v-model="formData.srid"
              :append-to-body="true"
              value-property="id"
              label-property="text"
              :options="sridOptions"
            />
          </div>
          <div class="mb-3">
            <div class="mb-1">Кодування файлу</div>
            <VsFormSelect 
              v-model="formData.encoding"
              :append-to-body="true" :options="['UTF-8', 'CP1251', 'LATIN-1']"
            />
          </div>
          <div>
            <div class="mb-1">Файл</div>
            <VsFormFile accept=".geojson,.zip" @change="uploadFile" />
          </div>
        </div>

        <div class="flex justify-end">
          <VsButton class="mr-2" @click="dialogVisible = false">Відмінити</VsButton>
          <VsButton type="primary" :disabled="!formData.file" @click="submitImport">Додати</VsButton>
        </div>
      </div>
    </VsDialog>
  </div>
</template>
<script>
import turf from "turf";
import VsButton from "@/components/basic/vs-button.vue";
import VsDialog from "@/components/overlays/dialog/vs-dialog.vue";
import fileApiMixins from "@/mixins/fileapi-mixin.js";
import VsFormFile from "../vs-form-file.vue";
import VsFormSelect from "../vs-form-select.vue";

export default {
  components: {
    VsButton,
    VsDialog,
    VsFormFile,
    VsFormSelect,
  },
  mixins: [fileApiMixins],
  props: {
    draw: { type: Object, default: () => {} },
    map: { type: Object, default: () => {} },
  },
  data() {
    return {
      formData: {
        srid: "",
        encoding: "",
        file: "",
        limit: true,
      },
      dialogVisible: false,
      sridOptions: [],
    };
  },
  computed: {
    aviableFeatures() {
      return !this.$parent.featuresList.length;
    },
  },
  watch: {
    dialogVisible(n) {
      if (n && !this.sridOptions.length) this.getSridOptions();
    },
  },
  methods: {
    exportFile() {
      const geometry = this.draw.getAll();

      this.fileDownload(JSON.stringify(geometry), "geometry_export", "geojson");
    },
    async getSridOptions() {
      try {
        const resp = await fetch("/api-user/suggest/gis.srid_from_setting?limit=50&json=1");
        if (resp.ok) {
          const { data = [] } = await resp.json();

          this.sridOptions = data;
        } else {
          this.sridOptions = [
            {
              initial: true,
              id: 4326,
              text: "4326 - WGS 84",
            },
          ];
        }
      } catch (error) {
        console.error(error);
        this.sridOptions = [
          {
            initial: true,
            id: 4326,
            text: "4326 - WGS 84",
          },
        ];
      }
    },
    async uploadFile($event) {
      try {
        const formData = new FormData();

        formData.append("file", $event[0]);

        const resp = await fetch("/file/upload/temp", {
          method: "POST",

          body: formData,
        });

        if (resp.ok) {
          const data = await resp.json();
          const path = data.result.file_path;
          this.formData.file = path;
        }
      } catch (error) {
        console.error(error);
        this.$vsNotify({
          type: "error",
          title: "Помилка",
          message: "Помилка при завантаженні файла геометрії",
        });
      }
    },
    async submitImport() {
      try {
        const resp = await fetch("/api-user/file2geojson", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.formData),
        });

        if (resp.ok) {
          const data = await resp.json();
          const { features } = data[0];

          features.forEach((feature) => {
            this.draw.add(feature);
          });
          const bbox = turf.bbox(features?.[0] || data[0] || features);

          this.map.fitBounds(bbox, { padding: 30, maxZoom: 17, duration: 0 });
          this.$emit("upload");
          this.dialogVisible = false;
        }
      } catch (error) {
        console.error(error);
        this.$vsNotify({
          type: "error",
          title: "Помилка",
          message: "Помилка імпорту файла геометрії",
        });
      }
    },
  },
};
</script>
<style lang="scss"></style>
