<template>
  <div class="layers">
    <VsFormGeomDataset v-if="dataset.length" class="mb-3" :dataset="dataset" :map="map" />
    <div v-if="radioLayers?.all?.length" class="mb-3">
      <div class="layers__title block text-sm text-gray-500">Усі шари</div>
      <VsFormCheckbox
        v-for="layer in radioLayers.all"
        :key="layer.id"
        v-model="activeLayers"
        class="layers__item"
        :label="layer.id"
        @change="toggleLayer(layer, $event)"
      >
        {{ layer?.name || layer?.title || layer?.id || "Назва відсутня" }}
      </VsFormCheckbox>
    </div>
    <div>
      <div class="layers__title block text-sm text-gray-500">Базові шари</div>
      <VsFormRadio
        v-for="layer in radioLayers.base"
        :key="layer.id" v-model="baseLayer" class="layers__item mb-2" :label="layer.id"
      >
        {{ layer?.name || layer?.title || layer.id || "Назва відсутня!" }}
      </VsFormRadio>
    </div>
  </div>
</template>
<script>
import VsFormGeomDataset from "./vs-form-geom-dataset.vue";
import VsFormRadio from "../vs-form-radio.vue";
import VsFormCheckbox from "../vs-form-checkbox.vue";

export default {
  components: { VsFormRadio, VsFormGeomDataset, VsFormCheckbox },
  props: {
    map: { type: Object, default: () => {} },
    dataset: { type: Array, default: () => [] },
    apiLayer: { type: String, default: () => "" },
    layers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      radioLayers: {
        base: [
          {
            id: "graymap",
            name: "Сіра карта",
            url: "https://basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
            service: "TMS",
            category: "Базові карти",
            attribution:
              "&copy; <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>, &copy; <a href='https://carto.com/attributions'>CARTO</a>",
            bounds1: "ua",
            image: "https://basemaps.cartocdn.com/light_all/10/601/348.png",
            position: 0,
            owner: "OSM",
            basemap: true,
          },
        ],
        all: [],
      },
      baseLayer: "graymap",
      activeLayers: [],
    };
  },
  watch: {
    baseLayer: {
      immediate: true,
      handler(value) {
        this.changeLayer(value);
      },
    },
  },
  async mounted() {
    this.radioLayers = await this.getLayers();

    const aviableBaseLayers = this.radioLayers.base.filter(layer => layer?.visible)

    const baseLayer = aviableBaseLayers?.[0]?.id ||  this.radioLayers.base[0].id

    if (this.baseLayer !== baseLayer) this.baseLayer = baseLayer;
  },
  methods: {
    // Pretty-clear, method sends a request to the api for layers
    async getLayers() {
      if (!this.apiLayer) return this.createLayersObject(this.layers);

      try {
        const resp = await fetch(this.apiLayer);

        if (resp.ok) {
          const data = await resp.json();
          const layers = data?.layers || data || [];
          return this.createLayersObject(layers.concat(this.layers));
        }
      } catch (error) {
        console.error(error);
        return this.createLayersObject(this.layers);
      }
    },
    // The method for changing baseLayer
    changeLayer(layerId) {
      const currentLayer = this.radioLayers.base.find((layer) => layer.id === layerId);
      this.map.changeBaseLayer(currentLayer, true);
    },
    // The method for toggling all layers
    toggleLayer(layer, $event) {
      this.map.setVisible(layer, $event);
    },
    // Just creates an object by groups **all** and **base**
    createLayersObject(layers) {
      return layers.reduce(
        (prev, layer) => {
          if (!layer?.enabled) return prev

          const key = layer.overlay ? "all" : "base";

          if (key === 'all' && layer.visible) { 
            this.activeLayers = this.activeLayers.concat(layer.id)
            this.map.setVisible(layer, true)
          }

          return {
            ...prev,
            [key]: prev[key].concat(layer),
          };
        },
        {
          all: [],
          base: this.radioLayers.base,
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.layers {
  width: 230px;
  top: 0;
  right: 40px;
  position: absolute;
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  max-height: 230px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
      width: 6px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #b5b5b5;
    }
    &::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }

  .vs-form-checkbox {
    flex-direction: column !important;
  }
  &__title{
    font-weight: 500;
    margin-bottom: 5px;
  }
  &__item{
    span,label{
      font-size: 13px;
      font-weight: 400;
    }
  }
}
</style>
