<template>
  <div class="flex flex-col gap-5">
    <div
      v-for="(item, index) in formScheme"
      :key="index"
      class="flex flex-col gap-2"
    >
      <div class="text-sm font-medium text-gray-900">
        {{ item?.ua || item?.label }}
      </div>
      <component
        :is="componentId(item.type)"
        v-model="formValues[item.key]"
        :options="item?.options || item?.original?.options || []"
        :data="item?.data || item?.original?.data"
        :multiple="item?.multiple || item?.original?.multiple"
        :input-type="item?.inputType || item?.original?.inputType"
        :clearable="item?.clearable || item?.original?.clearable"
        :label="item?.original?.label"
        :input-id="item?.original?.inputId || item?.inputId"
        :disabled="item?.original?.inputId || item?.disabled"
        :col-model="item?.original?.colModel || item?.colModel"
        :now="item?.original?.now || item?.now"
        :append-to-body="true"
      />
    </div>
  </div>
</template>

<script>
import formComponents from "@/misc/import-file.js";
import axios from "axios";

export default {
  components: { ...formComponents },
  props: {
    scheme: { type: Array, default: () => null },
    value: { type: [Object, Array] },
    api: { type: String, default: () => "" },
    token: { type: String, default: () => "" },
  },
  data() {
    return {
      formValues: {},
      formScheme: null,
    };
  },
  computed: {
    formIsAdd() {
      const url = window.location.href;
      return url.includes('add');
    },
  },
  watch: {
    formValues: {
      deep: true,
      handler(n, old) {
        if (!Object.values(old || {}).length) return;

        this.$emit("input", n);
        this.$emit("change", n);
      },
    },
  },
  async mounted() {
    await this.setFromData();
    this.formScheme = await this.getFormScheme();
  },
  methods: {
    componentId(type) {
      return `vs-form-${type.toLowerCase()}`;
    },
    async setFromData() {
      const formObjectValues = this?.api
        ? await this.getFormData()
        : this.value;

        console.log(formObjectValues);

      this.formValues = formObjectValues;
    },
    async getFormScheme() {
      if (!this.api && !this.scheme)
        throw new Error("Form error: Scheme or API url is required!");

      if (!this.api) return this.scheme;

      try {
        const { data } = await axios.get(this.api);

        return data?.components || data;
      } catch (error) {
        console.error(error);
      }
    },
    async getFormData() {
      if (!this.token || this.formIsAdd) return {};
      const urlData = "/api/" + this.token + "/" + this.token;

      try {
        const { data } = await axios.get(urlData);

        return data;
      } catch (error) {
        console.error(error);
        return {}
      }
    },
  },
};
</script>

<style lang="scss"></style>
