<template>
  <div class="form-geom__feature flex items-center text-xs transition-all" :class="featureActiveState">
    <div class="mr-auto ">
      Тип: <span class="font-bold">{{ data.geometry.type }}</span>
    </div>
    <div
      class="mr-3"
      v-html="additionalInfo()"
    />
    <div
      class="bg-gray-100 py-1.5 px-2 rounded-lg text-md mr-1 cursor-pointer hover:bg-blue-500 hover:text-white transition-all"
      title="Наблизити"
      @click="zoomTo"
    >
      <i class="ti ti-zoom-in" />
    </div>
    <div
      class="bg-gray-100 py-1.5 px-2 rounded-lg text-md cursor-pointer hover:bg-blue-500 hover:text-white transition-all"
      title="Видалити"
      @click="$emit('delete')"
    >
      <i class="ti ti-x" />
    </div>
  </div>
</template>
<script>
import turf from 'turf';
import { convertArea } from '@turf/helpers';
import length from '@turf/length';

export default {
  props: {
    data: { type: Object, default: () => {} },
    map: { type: Object, default: () => { } },
    draw: { type: Object, default: () => { } },
    selectedFeatures: {type: Array, default: () => []}
  },
  data() {
    return {};
  },
  computed: {
    featureActiveState() { 
      if (this.selectedFeatures.includes(this.data.id)) return 'bg-gray-50'

      return ''
    },
  },
  methods: {
    // here is pretty clear
    zoomTo() {
      const bbox = turf.bbox(this.data.geometry);

      this.map.fitBounds(bbox, { duration: 1, maxZoom: 17, padding: 30 });

      this.highlightFeature()
    },
    highlightFeature() { 
      const { id } = this.data

      this.draw.changeMode('direct_select', {
        featureId: id
      })

      console.log();
    },
    // Create additional info by type for feature element
    additionalInfo() {
      const { type } = this.data.geometry;

      if (type === 'Point') {
        const coordinates = this.data.geometry.coordinates.map((coord) => coord.toFixed(5));
        return `<div class="text-xs">Центр: <b>${coordinates.join(',')}</b></div>`;
      } if (type === 'Polygon') {
        const area = turf.area(this.data);

        const areaLabel = Math.floor(area) < 100000
          ? `${area.toFixed(1)}м²`
          : `${convertArea(area, 'meters', 'kilometers').toFixed(1)}км²`;

        return `<div class="text-xs">Площа: <b>${areaLabel}</b></div>`;
      }
      return `<div class="text-xs">Відстань: <b>${length(this.data).toFixed(1)}км</b></div>`;
    },
  },
};
</script>
<style lang="scss"></style>
