<!-- eslint-disable -->
<template>
  <div>
    <div class="flex items-center">
      <span
        v-if="inactiveText.length"
        :class="[
          `mr-3 text-${size} font-medium`,
          { [`text-${color}-500`]: !value },
          [disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'],
        ]"
        @click="toggleSwitch(!value)"
      >
        {{ inactiveText }}
      </span>

      <span
        v-if="inactiveIcon"
        :class="[
          `mr-3 text-${size} font-medium`,
          { [`text-${color}-500`]: !value },
          [disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'],
        ]"
        @click="toggleSwitch(!value)"
      >
        <i :class="inactiveIcon"></i>
      </span>

      <label
        class="relative inline-flex items-center"
        :class="[disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer']"
      >
        <input id="switchId" v-model="modelValue" type="checkbox" :disabled="disabled" :class="switchClasses" />
      </label>
      <label
        v-if="label"
        for="switchId"
        class="text-gray-500 ms-3"
        :style="{ fontWeight: 'normal' }"
        :class="[`text-${size}`, [disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer']]"
      >
        {{ label }}
      </label>
      <span
        v-if="activeText.length"
        :class="[
          `ml-3 text-${size} font-medium cursor-pointer`,
          { [`text-${color}-500`]: value },
          [disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'],
        ]"
        @click="toggleSwitch(!value)"
      >
        {{ activeText }}
      </span>
      <span
        v-if="activeIcon"
        :class="
          ([`ml-3 text-${size} font-medium`, { [`text-${color}-500`]: value }],
          [disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'])
        "
        @click="toggleSwitch(!value)"
      >
        <i :class="activeIcon"></i>
      </span>
    </div>
  </div>
</template>

<script>
import filterElementMx from '@/components/form/mixins/vs-form-mx';

export default {
  mixins: [filterElementMx],
  props: {
    value: { type: Boolean, default: () => false },
    activeText: { type: String, default: () => '' },
    inactiveText: { type: String, default: () => '' },
    activeIcon: { type: String, default: () => '' },
    inactiveIcon: { type: String, default: () => '' },
    color: { type: String, default: () => 'blue' },
  },
  computed: {
    switchClasses() {
      return {
        'vs-form-switch flex items-center relative bg-gray-100 border-transparent text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 disabled:opacity-50 disabled:pointer-events-none checked:bg-none before:inline-block before:bg-white before:translate-x-0 checked:before:translate-x-full before:rounded-full before:shadow before:transform before:ring-0 before:transition before:ease-in-out before:duration-200': true,
        'w-9 h-5 before:w-4 before:h-4': this.size === 'xs',
        'w-11 h-6 before:w-5 before:h-5': this.size === 'sm',
        'w-[3.25rem] h-7 before:w-6 before:h-6': this.size === 'md',
        'w-[4.25rem] h-9 before:w-8 before:h-8': this.size === 'lg',
        [`checked:text-${this.color}-600 checked:before:bg-${this.color}-200 checked:bg-${this.color}-600`]: true,
      };
    },
    modelValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    toggleSwitch($event) {
      const value = $event.target ? $event.target.checked : $event;
      if (this.disabled) return;
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.vs-form-switch:focus {
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
}
</style>
