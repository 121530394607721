<template>
  <div
    ref="popover"
    class="w-fit relative vst-popover"
    @mouseover="handleMouseHover"
    @mouseleave="handleMouseHover"
  >
    <a class="text-center">
      <div
        ref="popoverRef"
        @click="togglePopover"
      >
        <slot name="reference">
          <button>
            {{ isHoverable ? 'Hover me!' : 'Click me!' }}
          </button>
        </slot>
      </div>
      <transition name="bounce">
        <div
          v-show="isVisible"
          ref="contentPop"
          class="vst-popover__content inline-block z-10 py-3 px-4 bg-white text-sm text-gray-600 rounded-md shadow-lg"
          :class="placement"
          :style="{
            width: width ? width + 'px' : 'fit-content',
            ...placementSettings(),
          }"
        >
          <div>
            <slot><span>Popover example</span></slot>
          </div>
        </div>
      </transition>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    placement: { type: String, default: 'right' },
    isHoverable: { type: Boolean, default: false },
    width: { type: [String, Number], default: 500 },
  },
  data() {
    return {
      isVisible: false,
      boundParams: null,
      slotParams: {
        width: 0,
        height: 0,
      },
      containerParams: {
        height: 0,
        width: 0,
      },
    };
  },
  mounted() {
    window.addEventListener('click', this.handleOutsideClick);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleOutsideClick);
  },
  methods: {
    handleMouseHover() {
      if (this.isHoverable) {
        this.handleClick();
      }
    },
    checkForScroll() {
      const funcType = this.isVisible
        ? 'addEventListener'
        : 'removeEventListener';

      window[funcType]('scroll', this.getSettings);
      window[funcType]('resize', this.getSettings);

      const getScrollContainer = (element) => {
        if (!element) return;
        const scrollType = getComputedStyle(element).overflow;

        if (scrollType.match('auto') || scrollType.match('scroll')) {
          element[funcType]('scroll', this.getSettings);
          element[funcType]('resize', this.getSettings);
        } else {
          getScrollContainer(element.parentElement);
        }
      };

      getScrollContainer(this.$refs.popover);
    },
    placementSettings() {
      const { placement } = this;
      const { slotParams } = this;
      const { boundParams } = this;
      const { containerParams } = this;

      const defaultLeft = `${boundParams?.left
        - containerParams?.width
        + slotParams?.width / 2
      }px`;

      if (placement === 'top') {
        return {
          top: `${boundParams?.top - containerParams?.height - 16}px`,
          left: defaultLeft,
        };
      } if (placement === 'bottom') {
        return {
          top: `${boundParams?.top + slotParams?.height + 16}px`,
          left: defaultLeft,
        };
      } if (placement === 'left') {
        return {
          top:
            `${boundParams?.top
            + slotParams.height / 2
            - containerParams?.height / 2
            }px`,
          left: `${boundParams?.left - containerParams?.width * 2 - 16}px`,
        };
      } if (placement === 'right') {
        return {
          top:
            `${boundParams?.top
            + slotParams.height / 2
            - containerParams?.height / 2
            }px`,
          left: `${boundParams?.left + slotParams.width + 16}px`,
        };
      }
    },
    togglePopover() {
      if (!this.isHoverable) {
        this.handleClick();
      }
    },
    getSettings() {
      const slotEl = this.$slots.reference[0].elm;

      this.slotParams = {
        height: slotEl?.offsetHeight,
        width: slotEl?.offsetWidth,
      };

      this.$nextTick(() => {
        this.containerParams = {
          height: this.$refs.contentPop.offsetHeight,
          width: this.$refs.contentPop.offsetWidth / 2,
        };
      });

      this.boundParams = this.$slots.reference[0].elm.getBoundingClientRect();
    },
    async handleClick() {
      this.getSettings();
      this.isVisible = !this.isVisible;
      this.checkForScroll();
    },
    handleOutsideClick($event) {
      if (!$event) {
        this.isVisible = false;
        return;
      }

      if (this.$refs?.popoverRef?.contains($event.target)) {
        return;
      }

      if (!this.$refs?.contentPop?.contains($event.target)) {
        this.isVisible = false;
        this.checkForScroll();
      } else {
        this.isVisible = true;
      }
    },
  },
};
</script>

<style lang="scss">
.vst-popover__content {
  z-index: 100000;
}

.vst-popover {
  &__content {
    position: fixed;
  }

  .right {
    &::before {
      content: '';
      width: 0;
      height: 0;
      left: -9px;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      border-style: solid;
      border-width: 11px 9px 11px 0;
      border-color: transparent white transparent transparent;
    }
  }

  .left {
    &::before {
      content: '';
      width: 0;
      height: 0;
      right: -9px;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      border-style: solid;
      border-width: 11px 0 11px 9px;
      border-color: transparent transparent transparent white;
    }
  }

  .bottom {
    &::before {
      content: '';
      position: absolute;
      width: 0;
      left: 50%;
      top: -8px;
      transform: translateX(-50%);
      height: 0;
      border-style: solid;
      border-width: 0 12.5px 8px 12.5px;
      border-color: transparent transparent #ffffff transparent;
    }
  }

  .top {
    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      bottom: -8px;
      transform: translateX(-50%);
      left: 50%;
      border-style: solid;
      border-width: 8px 12.5px 0 12.5px;
      border-color: #ffffff transparent transparent transparent;
    }
  }
}

.bounce-enter-active,
.bounce-leave-active {
  animation-duration: 0.3s;
  animation-fill-mode: both;
}

@keyframes bounce-in-left {
  0% {
    transform: scale(0) translate(5%, 0%);
    transform-origin: right center;
  }
  50% {
    transform: scale(1.25) translate(0, 0%);
    transform-origin: right center;
  }
  100% {
    transform: scale(1) translate(0, 0%);
    transform-origin: right center;
  }
}

@keyframes bounce-in-right {
  0% {
    transform: scale(0) translate(-5%, 0%);
    transform-origin: left center;
  }
  50% {
    transform: scale(1.25) translate(0, 0%);
    transform-origin: left center;
  }
  100% {
    transform: scale(1) translate(0, 0%);
    transform-origin: left center;
  }
}

@keyframes bounce-in-top {
  0% {
    transform: scale(0) translate(0%, 30%);
    transform-origin: bottom center;
  }
  50% {
    transform: scale(1.25) translate(0%, 0);
    transform-origin: bottom center;
  }
  100% {
    transform: scale(1) translate(0%, 0);
    transform-origin: bottom center;
  }
}

@keyframes bounce-in-bottom {
  0% {
    transform: scale(0) translate(0%, -30%);
    transform-origin: top center;
  }
  50% {
    transform: scale(1.25) translate(0%, 0);
    transform-origin: top center;
  }
  100% {
    transform: scale(1) translate(0%, 0);
    transform-origin: top center;
  }
}

.bounce-enter-active.right {
  animation-name: bounce-in-right;
}

.bounce-enter-active.left {
  animation-name: bounce-in-left;
}

.bounce-leave-active.right {
  animation-name: bounce-in-right;
  animation-direction: reverse;
}

.bounce-leave-active.left {
  animation-name: bounce-in-left;
  animation-direction: reverse;
}

.bounce-enter-active.top {
  animation-name: bounce-in-top;
}

.bounce-enter-active.bottom {
  animation-name: bounce-in-bottom;
}

.bounce-leave-active.top {
  animation-name: bounce-in-top;
  animation-direction: reverse;
}

.bounce-leave-active.bottom {
  animation-name: bounce-in-bottom;
  animation-direction: reverse;
}
.bounce-enter-active.right {
  animation-name: bounce-in-right;
}

.bounce-enter-active.left {
  animation-name: bounce-in-left;
}

.bounce-leave-active.right {
  animation-name: bounce-in-right;
  animation-direction: reverse;
}

.bounce-leave-active.left {
  animation-name: bounce-in-left;
  animation-direction: reverse;
}

.bounce-enter-active.top {
  animation-name: bounce-in-top;
}

.bounce-enter-active.bottom {
  animation-name: bounce-in-bottom;
}

.bounce-leave-active.top {
  animation-name: bounce-in-top;
  animation-direction: reverse;
}

.bounce-leave-active.bottom {
  animation-name: bounce-in-bottom;
  animation-direction: reverse;
}
</style>
