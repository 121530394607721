<template lang="">
  <fieldset class="flex flex-wrap gap-3">
    <div
      v-for="color in dynamicColors"
      :key="color"
    >
      <input
        :id="color"
        type="radio"
        :name="color"
        :value="color"
        class="peer hidden"
        :checked="selectedColor === color"
        :disabled="disabled"
        @change="updateSelectedColor($event)"
      >

      <label
        :for="color"
        class="transition-all block rounded-full shadow-sm peer-checked:ring-2 peer-checked:ring-offset-2"
        :class="[
          'bg-' + color + '-500',
          'peer-checked:ring-' + color + '-500',
          disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer',
          { 'h-3 w-3': size === 'xs' },
          { 'h-5 w-5': size === 'sm' },
          { 'h-8 w-8': size === 'md' },
          { 'h-10 w-10': size === 'lg' },
          { 'h-12 w-12': size === 'xl' },
        ]"
      />
    </div>
  </fieldset>
</template>

<script>
export default {
  props: {
    colors: {
      type: Array,
      default: () => [],
    },
    paletteSize: {
      type: Number,
      default: 8,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
  },
  data() {
    return {
      selectedColor: null,
    };
  },
  computed: {
    dynamicColors() {
      return this.colors.length > 0 ? this.colors : this.generateDynamicColors();
    },
  },
  methods: {
    updateSelectedColor($event) {
      this.selectedColor = $event.target.value;
      this.$emit('change', this.selectedColor);
    },
    generateDynamicColors() {
      const baseColors = ['red', 'orange', 'yellow', 'lime', 'green', 'emerald', 'teal', 'cyan', 'sky', 'blue',
        'indigo', 'violet', 'purple', 'fuchsia', 'pink'];
      return baseColors.slice(0, this.paletteSize);
    },
  },
};
</script>

<style></style>
