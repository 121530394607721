/* eslint-disable */
import {alertHeader, confirmHeader, confirmOrAlertFooter} from "./templates/modal-templates";
export default {
  install(Vue, options) {
    // Биндится через v-my-directive
    Vue.directive("my-directive", {
      bind(el, binding, vnode, oldVnode) {
        console.log(el, binding, vnode, oldVnode);
      },
    });

    Vue.prototype.$openDialog = (componentOptions) => {
      const {
        title = "",
        html = "",
        message = "",
        componentName = "VsComponent",
        props,
        component,
        options = [],
        optionsType = "radio",
        alert = null,
        confirm = null,
        prompt = null,
        cancel,
        persistent = true,
      } = componentOptions;
    
      const components = {
        VsDialog: () => import("@/components/overlays/dialog/vs-dialog.vue"),
        VsButton: () => import("@/components/basic/vs-button.vue"),
        VsFormRadio: () => import("@/components/form/vs-form-radio.vue"),
        VsFormCheckbox: () => import("@/components/form/vs-form-checkbox.vue"),
        VsFormText: () => import("@/components/form/vs-form-text.vue"),
        VsFormNumber: () => import("@/components/form/vs-form-number.vue"),
      };
    
      components[componentName] = component;

      const createPropsString = () => {
        if (!props) return "";

        const propsObject = Object.entries(props).filter(([key]) => !["component", "title"].includes(key));
        const propsString = propsObject.reduce((prev, el) => {
          const [key, value] = el;

          let formatValue;
          if (typeof value === "string") {
            formatValue = `'${value}'`;
          } else if (typeof value === "object") {
            const stringObject = JSON.stringify(value).replaceAll('"', "'");
            formatValue = stringObject;
          } else {
            formatValue = value;
          }

          return `${prev} :${key}="${formatValue}"`;
        }, "");

        return propsString;
      };
    
      const createCommonHeader = () => {
        if (alert) return alertHeader;
        if (confirm) return confirmHeader;

        return '';
      };
    
      const createCommonFooter = () => {
        if (alert || confirm) return confirmOrAlertFooter;
        return '';
      };
    
      let template = `
        ${createCommonHeader()}
        <div class="p-4 overflow-y-auto" slot="content">${html}</div>
        ${createCommonFooter()}`;
    
      if (component) {
        template = `
          ${createCommonHeader()}
          <div class="p-4 overflow-y-auto" slot="content">
            <component ${createPropsString()} :is="'${componentName}'" @cancel="handleClose" @submit="handleClose" />
          </div>
          ${createCommonFooter()}`;
      }
    
      if (options.length && optionsType) {
        const type = optionsType[0].toUpperCase() + optionsType.slice(1);
        const optionsString = JSON.stringify(options);
        template = `
          ${createCommonHeader()}
          <div class="p-4 overflow-y-auto" slot="content">
            <VsForm${type} :options='${optionsString}' @cancel="handleClose" @submit="handleClose" v-model="selectedOptions" />
          </div>
          ${createCommonFooter()}`;
      }
    
      if (prompt) {
        const type = prompt[0].toUpperCase() + prompt.slice(1);
        switch (prompt) {
          case "number":
          case "text":
            template = `
              ${createCommonHeader()}
              <div class="p-4 overflow-y-auto" slot="content">
                <VsForm${type} ${createPropsString()} @cancel="handleClose" @submit="handleClose" :autofocus="true" />
              </div>
              ${createCommonFooter()}`;
            break;
    
          default:
            break;
        }
      }
    
      const elem = document.createElement("div");
    
      Vue.component("ComponentModal", {
        components,
        props,
        data() {
          return {
            visible: true,
            selectedOptions: [],
          };
        },
        watch: {
          visible(n) {
            if (!n) {
              this.handleClose();
            }
          },
        },
        methods: {
          handleClose() {
            this.visible = false;
            setTimeout(() => {
              app.$destroy();
              document.getElementById("component-modal")?.remove();
            }, 300);
          },
        },
        template: `
          <VsDialog class="vsTailwind" :visible.sync="visible" :title="'${title}'" :message="'${message}'"  :cancel="${cancel}" :persistent="${persistent}" >
            ${template}
          </VsDialog>`,
      });
    
      elem.setAttribute("id", "component-modal");
      elem.innerHTML = '<div is="component-modal"></div>';
      document.body.before(elem);
    
      const app = new Vue({ el: "#component-modal" });
    };
    

    Vue.prototype.$vsNotify = async (options = {}) => {
      const {
        position = "bottom-right",
        duration = 4000,
        title = "Example",
        message = "Example",
        type = "info",
      } = options;
      const listId = `vs-notify-list-${position}`;

      const getList = () => {
        const notifyList = document.getElementById(listId);

        if (!notifyList) {
          const list = document.createElement("div");
          list.id = listId;
          list.className = `vs-notify-list ${position}`;
          document.body.append(list);

          return list;
        }

        return notifyList;
      };

      const closeToast = () => {
        toastDiv.style.transform = "translate(0%, -20px)";
        toastDiv.style.opacity = "0";

        setTimeout(() => {
          toastDiv.remove();

          const vsList = getList();

          if (!vsList.childNodes.length) {
            vsList.remove();
          }
        }, 300);
      };

      const toastDiv = document.createElement("div");
      toastDiv.close = closeToast;
      const elemToastId = `toast-${Math.floor(Math.random() * 1000)}`;
      toastDiv.className = "vs-toast";

      const innerDiv = document.createElement("div");
      innerDiv.id = elemToastId;
      innerDiv.setAttribute("is", "vs-toast");

      ["title", "message", "type"].forEach((el) => {
        innerDiv.setAttribute(el, options?.[el] || "");
      });

      toastDiv.prepend(innerDiv);

      getList().append(toastDiv);
      Vue.component("VsToast", () => import("@/components/basic/vs-toast.vue"));

      const app = new Vue({ el: document.getElementById(elemToastId) });

      setTimeout(() => {
        toastDiv.style.transform = "translate(0%, 0%)";
      }, 100);

      setTimeout(() => {
        closeToast();
      }, duration);
    };
  },
};
