// TODO: onload event
if (!window.head) {
  window.head = {};
}
head.load = function () {
  try {
    const data = Array.from(arguments);
    if (!window.headLoadedFiles) {
      window.headLoadedFiles = [];
    }
    const cb = typeof arguments[arguments.length - 1] === 'function' ? arguments[arguments.length - 1] : null;
    const files = data.filter((el) => typeof el !== 'function')
      .reduce((f, el) => f.concat(el), [])
      .filter((el) => el && !window.headLoadedFiles[(el.url || el.split('?')[0])]);
    // console.log(files);
    Promise.all(files.map((el) => loadFile1(el))).then((el) => {
      if (cb) { cb(); }
    });
  } catch (e) {
    console.log(e);
  }
};
head.js = head.load;
head.promise = function (data) {
  return new Promise((rsv, rjct) => {
    head.load(data, () => {
      rsv();
    });
  });
};

function loadFile1(url) {
  let type = false;
  if (typeof url === 'object') {
    type = url.type;
    url = url.url;
  }
  const _url = url.split('?')[0];
  return new Promise((rsv, rjct) => {
    function onload(event) {
      delete window.headLoadedFiles[`_${_url}`];
      window.headLoadedFiles[_url] = event.type;
      rsv(event.type);
    }

    let file;
    /* avoid multiple load same script. all listener wait one loader */
    if (window.headLoadedFiles[`_${_url}`]) {
      file = window.headLoadedFiles[`_${_url}`];
    } else {
      if (_url.includes('.css')) {
        file = document.createElement('link');
        file.href = url;
        file.type = 'text/css';
        file.rel = 'stylesheet';
      } else {
        file = document.createElement('script');
        file.type = 'text/javascript';
        file.src = url;
        if (type) {
          file.type = type;
        }
      }
      window.headLoadedFiles[`_${_url}`] = file;
      document.head.appendChild(file);
    }
    file.addEventListener('load', onload);
    file.addEventListener('error', onload);
  });
}
export default head;
