<template>
  <div>
    <div class="block text-sm text-gray-500 layers__title">Набори</div>
    <VsFormCheckbox
      v-for="layer in dataset"
      :key="layer?.id || layer?.data || layer?.name || layer.title" v-model="activeLayers"
      class="layers__item" :label="layer?.id || layer.data" @change="createLayer(layer, $event)"
    >
      {{ layer?.name || layer.title }}
    </VsFormCheckbox>
  </div>
</template>
<script>
import VsFormCheckbox from "../vs-form-checkbox.vue";

export default {
  components: { VsFormCheckbox },
  props: {
    dataset: { type: Array, default: () => [] },
    map: { type: Object, default: () => { } },
  },
  data() {
    return {
      activeLayers: [],
    };
  },
  mounted() {
    this.startDataset()
  },
  methods: {
    // Create layer with settings by layer info
    createLayer(layer, $event) {
      const url = this.createLayerUrl(layer);
      const id = layer?.data;
      const style = Object.assign(
        {},
        layer.style,
        layer.attrFill ? { colorAttr: layer.attrFill } : {}
      );

      const layerData = {
        ...layer,
        url,
        id,
        style,
      };

      this.map.setVisible(layerData, $event);
    },
    // Create layer url by type via layer from dataset
    createLayerUrl({ service, data, nocache = false}) {
      switch (service) {
        case "vtile":
          return `/api-user/vtile/${data}/ua/{z}/{x}/{y}.vmt?nocache=${nocache?'1':'0'}`;

        case "geojson":
          return `/api-user/geojson/${data}.json`;

        default:
          throw new Error(`Dataset error: service - ${service} doesn't exist`);
      }
    },
    // Will start dataset work and the first of all will check all layer on key visible - if key visible equal true, will toggle it
    startDataset() {
      this.dataset.forEach(layer => {
        if (layer?.visible) {
          this.activeLayers = this.activeLayers.concat(layer?.id || layer.data)
          this.createLayer(layer, true)
        } 
      })
    }
  },
};
</script>
<style lang="scss"></style>
