<template lang="">
  <div class="flex gap-2 items-center">
    <input
      v-model="currentColor"
      type="color"
      :class="{
        '!cursor-not-allowed': disabled,
        'cursor-pointer': !disabled,
      }"
      :disabled="disabled"
      style="outline: none"
    />
    <input v-model="currentColor" :class="inputClasses" @blur="handleBlur" />
  </div>
</template>

<script>
import filterElementMx from '@/components/form/mixins/vs-form-mx';

export default {
  mixins: [filterElementMx],
  props: {
    value: {
      type: String,
      default: '#000',
    },
  },
  data() {
    return {
      currentColor: '',
    };
  },
  computed: {
    inputClasses() {
      return [
        'py-2 px-3 transition-all block w-full rounded-md focus:ring-blue-500 text-md border border-gray-200',
        { [`!text-${this.size}`]: this.size !== 'md' },
        { 'text-base': this.size === 'md' },
      ];
    },
  },
  watch: {
    currentColor(n) {
      this.$emit('input', n);
      this.$emit('change', n);
    },
  },
  created() {
    this.currentColor = this.value;
  },
  methods: {
    handleBlur() {
      if (!this.isValidColor(this.currentColor)) {
        this.currentColor = '#000';
      }
    },
    isValidColor(color) {
      const el = new Option().style;
      el.color = color;
      return el.color !== '';
    },
  },
};
</script>

<style lang="scss"></style>
