<template>
  <div>
    <template v-if="!options.length">
      <div
        class="hs-dropdown relative inline-flex [--auto-close:inside]"
        :class="'[--placement:' + placement + ']'"
      >
        <div
          id="hs-dropdown-auto-close-false"
          ref="dropdownBtn"
          class="hs-dropdown-toggle"
          :class="{ 'dropdown-disabled': disabled }"
          @click="handleClick"
        >
          <slot name="reference">
            <button
              id="hs-dropdown-with-dividers"
              type="button"
              class="vs-dropdown__btn relative py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border
              bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2
              focus:ring-offset-white focus:ring-blue-600 transition-all text-sm"
            >
              <div>{{ title }}</div>
              <div
                class="vs-dropdown__arrow"
                :class="{ active: isDropdownOpen }"
              >
                <i class="el-icon-arrow-up" />
              </div>
            </button>
          </slot>
        </div>

        <div
          :class="{ 'hidden-list': disabled }"
          aria-labelledby="hs-dropdown-auto-close-inside"
          class="py-2 vs-dropdown__list w-fit hs-dropdown-menu hidden transition-[opacity,margin] duration
          hs-dropdown-open:opacity-100 bg-white shadow-md rounded-md p-2 mt-2 divide-y divide-gray-100"
          style="z-index: 10001"
        >
          <slot />
        </div>
      </div>
    </template>

    <template v-else>
      <div>
        <div
          ref="toggle"
          class="relative inline-block text-left"
          @click="isDropdownOpen = !isDropdownOpen"
        >
          <slot name="reference">
            <button
              type="button"
              class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm
              font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              {{ title }}
              <svg
                class="-mr-1 h-5 w-5 text-gray-400 vs-dropdown__arrow"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
                :class="{ active: isDropdownOpen }"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </slot>

          <transition name="slide">
            <div
              ref="options"
              class="options absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div
                v-for="option in options"
                :key="option.label"
                class="py-1"
              >
                <template v-if="option.command === 'divider'">
                  <div class="divider" />
                </template>

                <template v-else>
                  <div
                    :id="option.label"
                    :class="[
                      'text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900',
                      option.disabled
                        ? 'hover:cursor-not-allowed'
                        : 'hover:cursor-pointer',
                    ]"
                    @click="selectOption(option)"
                  >
                    <div
                      class="d-flex items-center"
                      style="height: 1.25rem; width: 100%"
                    >
                      <i
                        v-if="option.icon"
                        style="margin-right: 0.75rem; opacity: 70%"
                        :class="'ti ti-' + option.icon + ' text-2xl'"
                      />
                      <span>{{ option.label }}</span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    placement: { type: String, default: () => 'bottom-right' },
    title: { type: String, default: () => 'Options' },
    value: { type: String, default: '' },
    disabled: { type: Boolean, default: () => false },
    options: { type: Array, default: () => [] },
  },
  data() {
    return {
      name: 'commander',
      isDropdownOpen: false,
    };
  },
  watch: {
    value(n) {
      this.$emit('input', n);
      this.isDropdownOpen = false;
    },
    isDropdownOpen(n) {
      if (this.options.length) {
        const optionsList = this.$refs.options;
        optionsList.style.position = 'fixed';

        if (n) {
          window.addEventListener('scroll', this.getSettings);
          window.addEventListener('resize', this.getSettings);
          optionsList.style.display = 'block';
          optionsList.style.zIndex = 100000000;
          this.getSettings();
        } else {
          window.removeEventListener('scroll', this.getSettings);
          window.removeEventListener('resize', this.getSettings);
          optionsList.style.display = 'none';
        }
      }
    },
  },
  mounted() {
    window.addEventListener('click', this.closeOnClickOutside);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.closeOnClickOutside);
  },
  methods: {
    handleClick() {
      if (this.disabled && this.isDropdownOpen) return;
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    selectOption(option) {
      if (!option.disabled) {
        this.$emit('command', option.command);
      }
    },
    closeOnClickOutside($event) {
      if (this.$refs?.toggle?.contains($event.target)) {
        return;
      }

      if (!this.$refs?.options?.contains($event.target)) {
        this.isDropdownOpen = false;
      }
    },
    getSettings() {
      const { placement } = this;
      const optionsList = this.$refs.options;
      document.body.appendChild(optionsList);

      const optionsListpRect = optionsList.getBoundingClientRect();
      const toggleRect = this.$refs.toggle.getBoundingClientRect();

      let top; let
        left;

      switch (placement) {
        case 'top':
          top = toggleRect.top - optionsListpRect.height;
          left = toggleRect.left - optionsListpRect.width / 2 + toggleRect.width / 2;
          break;

        case 'bottom':
          top = toggleRect.bottom;
          left = toggleRect.left - optionsListpRect.width / 2 + toggleRect.width / 2;
          break;

        case 'left':
          top = toggleRect.top
              - optionsListpRect.height / 2
              + toggleRect.height / 2;
          left = toggleRect.left - optionsListpRect.width;
          break;

        case 'right':
          top = toggleRect.top
              - optionsListpRect.height / 2
              + toggleRect.height / 2;
          left = toggleRect.right;
          break;

        case 'top-right':
          top = toggleRect.top - optionsListpRect.height;
          left = toggleRect.right;
          break;

        case 'top-left':
          top = toggleRect.top - optionsListpRect.height;
          left = toggleRect.left - optionsListpRect.width;
          break;

        case 'bottom-right':
          top = toggleRect.bottom;
          left = toggleRect.right;
          break;

        case 'bottom-left':
          top = toggleRect.bottom;
          left = toggleRect.left - optionsListpRect.width;
          break;

        default:
          break;
      }

      optionsList.style.top = `${top}px`;
      optionsList.style.left = `${left}px`;
    },
  },
};
</script>

  <style lang="scss" scoped>
  .hidden-list {
    pointer-events: none !important;
    display: none !important;
  }
  .dropdown-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .vs-dropdown {
    &__btn:focus {
      box-shadow: none;
    }

    &__list {
      div {
        transition: all 0.3s;
        padding: 5px 5px;
        border-radius: 5px;
      }
    }

    &__arrow {
      transition: transform 0.3s;
      &.active {
        transform: rotate(180deg);
      }
    }
  }

  .divider::after {
    content: '';
    box-sizing: border-box;
    height: 1px;
    width: 100%;
    display: block;
    background-color: #e5e7eb;
    opacity: 50%;
  }

  .slide-enter-active,
  .slide-leave-active {
    transition: transform 0.3s, opacity 0.5s;
  }

  .slide-enter {
    opacity: 0;
    transform: translateY(-10px);
  }

  .slide-leave-to {
    opacity: 0;
    transform: translateY(-5px);
  }

  .options {
    display: none;
  }
  </style>
