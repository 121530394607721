<template>
  <div class="relative">
    <label v-if="label" for="message" class="mb-2 text-gray-500 cursor-pointer">
      {{ label }}
    </label>
    <textarea
      v-model="vModel"
      :placeholder="placeholder"
      :disabled="disabled"
      class="py-3 px-4 transition-all pr-11 block w-full border border-gray-200 rounded-md focus:border-blue-500 focus:ring-blue-500"
      :class="{
        'text-xs': size === 'mini',
        'text-sm': size === 'default',
        'text-lg': size === 'large',
        'cursor-not-allowed opacity-50': disabled,
        'cursor-pointer': !disabled,
      }"
      :rows="rows"
      cols="70"
    />
    <transition name="slide">
      <div
        v-if="clearable && vModel"
        class="text-color-gray-50 cursor-pointer text-sm absolute right-2 top-2"
        @click="clearInput"
      >
        <i class="ti ti-x" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "Введіть текст...",
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: () => "default",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    rows() {
      if (this.size === "small") {
        return 2;
      }

      if (this.size === "small") {
        return 6;
      }

      return 4;
    },
    vModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        this.$emit("change", value);
      },
    },
  },
  methods: {
    clearInput() {
      this.$emit("input", "");
      this.$emit("change", "");
    },
  },
};
</script>

<style lang="scss" scoped>
.vs-textarea {
  &:focus {
    border-color: transparent;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.1s, opacity 0.1s;
}

.slide-enter {
  opacity: 0;
  transform: translateY(-10px);
}

.slide-leave-to {
  opacity: 0;
  transform: translateY(-5px);
}

textarea:focus {
  box-shadow: none;
  outline: none;
}
</style>
