<!-- eslint-disable -->
<template>
  <div class="relative" ref="datepicker">
    <input
      ref="toggle"
      type="text"
      :class="inputClasses"
      placeholder="Оберіть дату.."
      :value="modelValue"
      :readonly="true"
      :disabled="disabled"
      @change="$emit('change', selectedDate ? formatDate(selectedDate) : '')"
      @input="$emit('input', selectedDate ? formatDate(selectedDate) : '')"
      @click="isOpen = !isOpen"
      style="outline: none"
    />
    <div v-if="clearable && selectedDate" class="absolute right-0 flex items-center pr-3.5 inset-y-0">
      <div class="text-color-gray-50 cursor-pointer text-sm" @click="clearSelectedDate">
        <i class="ti ti-x"></i>
      </div>
    </div>
    <div
      v-show="isOpen"
      class="datepicker-options datepicker-picker inline-block rounded-md bg-white shadow-lg p-4"
      ref="datepickerOptions"
    >
      <div class="datepicker-header">
        <div class="datepicker-controls flex justify-between items-center">
          <div
            class="bg-white rounded-md text-gray-500 text-md p-2.5 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 prev-btn m-0"
            @click="prevYear"
          >
            <i class="ti ti-chevrons-left"></i>
          </div>
          <div
            class="bg-white rounded-md text-gray-500 text-md p-2.5 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 prev-btn m-0"
            @click="prevMonth"
          >
            <i class="ti ti-chevron-left"></i>
          </div>
          <div
            class="rounded-md text-gray-900 py-2.5 px-5 hover:bg-gray-100 focus:outline-none view-switch"
            :class="{
              'text-xs': size === 'mini',
              'text-sm': size === 'default',
              'text-md': size === 'large',
            }"
          >
            {{ currentMonthLabel }}
          </div>
          <div
            class="bg-white rounded-md text-gray-500 text-md p-2.5 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 prev-btn m-0"
            @click="nextMonth"
          >
            <i class="ti ti-chevron-right"></i>
          </div>
          <div
            class="bg-white rounded-md text-gray-500 text-md p-2.5 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 prev-btn m-0"
            @click="nextYear"
          >
            <i class="ti ti-chevrons-right"></i>
          </div>
        </div>
      </div>
      <div class="datepicker-main p-1">
        <div class="datepicker-view flex">
          <div class="days">
            <div class="days-of-week grid grid-cols-7 mb-1">
              <span
                v-for="day in weekDays"
                class="dow text-center h-6 leading-6 font-medium text-gray-500"
                :class="{
                  'text-xs': size === 'mini',
                  'text-sm': size === 'default',
                  'text-md': size === 'large',
                }"
                >{{ day }}</span
              >
            </div>
            <div class="datepicker-grid w-64 grid grid-cols-7">
              <span
                v-for="(cell, idx) in visibleDateCells"
                :key="idx + cell.date"
                class="datepicker-cell hover:bg-gray-100 block flex-1 leading-9 border-0 rounded-md cursor-pointer text-center text-gray-900 day"
                :class="{
                  prev: cell.date < firstDayOfMonth,
                  next: cell.date > lastDayOfMonth,
                  'focused !bg-blue-500 !text-white': isSelectedDate(cell.date),
                  'text-blue-500': isCurrentDate(cell.date),
                  'text-gray-400': !isDateInCurrentMonth(cell.date),
                  'text-xs': size === 'mini',
                  'text-sm': size === 'default',
                  'text-md': size === 'large',
                  'cursor-not-allowed': disabled,
                }"
                @click="selectDate(cell.date)"
              >
                {{ cell.day }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VsFormMixin from './mixins/vs-form-mx';

export default {
  mixins: [VsFormMixin],
  props: {
    value: {
      type: [String, Date],
      default: null,
    },
    appendToBody: { type: Boolean, default: false },
    now: { type: [Number, String, Boolean], default: 0 },
  },
  data() {
    return {
      selectedDate: null,
      isOpen: false,
      currentDate: new Date(),
      weekDays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Su', 'Mo'],
    };
  },
  computed: {
    modelValue() {
      return this.selectedDate ? this.formatDate(this.selectedDate) : '';
    },
    inputClasses() {
      return [
        'vs-form-datepicker block w-full border border-gray-200 rounded-md py-2 px-3',
        { [`!text-${this.size}`]: this.size !== 'md' },
        { 'text-base': this.size === 'md' },
        { 'hover:cursor-not-allowed': this.disabled },
      ];
    },
    currentMonthLabel() {
      const options = { year: 'numeric', month: 'long', locale: 'uk-UA' };
      return this.currentDate.toLocaleDateString('uk-UA', options);
    },
    firstDayOfMonth() {
      const firstDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
      return firstDay.getDay();
    },
    lastDayOfMonth() {
      const lastDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
      return lastDay.getDate();
    },
    visibleDateCells() {
      const cells = [];
      const prevMonthLastDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 0).getDate();

      const offset = (this.firstDayOfMonth + 6) % 7;

      for (let i = prevMonthLastDay - offset + 1; i <= prevMonthLastDay; i += 1) {
        cells.push({
          day: i,
          date: new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() - 1, i),
        });
      }

      for (let i = 1; i <= this.lastDayOfMonth; i += 1) {
        cells.push({
          day: i,
          date: new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), i),
        });
      }

      const remainingCells = 42 - cells.length;
      for (let i = 1; i <= remainingCells; i += 1) {
        cells.push({
          day: i,
          date: new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, i),
        });
      }

      return cells;
    },
  },
  watch: {
    selectedDate() {
      const dateToemit = this.formatDate(this.selectedDate);
      this.$emit('input', dateToemit);
      this.$emit('change', dateToemit);
    },
    isOpen(n) {
      const optionsList = this.$refs.datepickerOptions;
      optionsList.style.position = 'fixed';

      if (n) {
        window.addEventListener('scroll', this.getSettings);
        window.addEventListener('resize', this.getSettings);
        optionsList.style.display = 'block';
        optionsList.style.zIndex = 100000000;
        this.getSettings();
      } else {
        window.removeEventListener('scroll', this.getSettings);
        window.removeEventListener('resize', this.getSettings);
        optionsList.style.display = 'none';
      }
    },
  },
  mounted() {
    // обирає дату за value або ставить сьогоднішню дату, якщо пропс now дорівнює 1
    window.addEventListener('click', this.closeOnClickOutside);
    if (this.now) {
      this.selectedDate = new Date();
    }
    this.selectedDate = this.parseDate(this.value);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.closeOnClickOutside);
  },
  methods: {
    closeOnClickOutside($event) {
      console.log($event.target);
      if (!this.$refs.datepicker?.contains($event.target) && !this.$refs.datepickerOptions?.contains($event.target)) {
        this.isOpen = false;
      }
    },
    parseDate(dateString) {
      if (!dateString) {
        return null;
      }

      const [day, month, year] = dateString.split('.');
      return new Date(`${year}-${month}-${day}`);
    },
    getSettings() {
      const optionsList = this.$refs.datepickerOptions;

      if (this.appendToBody) {
        document.body.appendChild(optionsList);
      }

      const optionsListpRect = optionsList.getBoundingClientRect();
      const toggleRect = this.$refs.toggle.getBoundingClientRect();

      const top = toggleRect.top;
      const bottom = toggleRect.bottom;
      const left = toggleRect.left;

      if (bottom + optionsListpRect.height >= window.innerHeight) {
        optionsList.style.top = `${top - optionsListpRect.height - 10}px`;
      } else {
        optionsList.style.top = `${bottom + 10}px`;
      }

      optionsList.style.left = `${left}px`;
    },
    selectDate(date) {
      this.isOpen = false;
      this.selectedDate = date;
    },
    prevMonth() {
      this.currentDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() - 1, 1);
    },
    prevYear() {
      this.currentDate = new Date(this.currentDate.getFullYear() - 1, this.currentDate.getMonth(), 1);
    },
    nextMonth() {
      this.currentDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 1);
    },
    nextYear() {
      this.currentDate = new Date(this.currentDate.getFullYear() + 1, this.currentDate.getMonth(), 1);
    },
    isSelectedDate(date) {
      return this.selectedDate && date.toDateString() === this.selectedDate.toDateString();
    },
    isCurrentDate(date) {
      const currentDate = new Date();
      return (
        date.getDate() === currentDate.getDate() &&
        date.getMonth() === currentDate.getMonth() &&
        date.getFullYear() === currentDate.getFullYear()
      );
    },
    isDateInCurrentMonth(date) {
      return date.getMonth() === this.currentDate.getMonth() && date.getFullYear() === this.currentDate.getFullYear();
    },
    formatDate(date) {
      if (!date) return '';

      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();

      return `${day}.${month}.${year}`;
    },
    clearSelectedDate() {
      this.selectedDate = '';
      this.$emit('change', '');
      this.$emit('input', '');
    },
  },
};
</script>

<style scoped>
.datepicker-options {
  display: fixed;
}
.vs-form-datepicker:focus {
  box-shadow: none;
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}
</style>
