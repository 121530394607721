import VsFormCheckbox from '@/components/form/vs-form-checkbox.vue'
import VsFormText from '@/components/form/vs-form-text.vue'
import VsButton from '@/components/basic/vs-button.vue'
import VsForm from '@/components/form/vs-form.vue'
import VsFormWrapper from '@/components/form/layout/vs-form-wrapper.vue'
import VsFormNumber from '@/components/form/vs-form-number.vue'
import VsFormRichtext from '@/components/form/vs-form-richtext.vue'
import VsFormColorPicker from '@/components/form/vs-form-color-picker.vue'
import VsFormColor from '@/components/form/vs-form-color.vue'
import VsFormSelect from '@/components/form/vs-form-select.vue'
import VsFormSelect2 from '@/components/form/vs-form-select.vue'
import VsFormAutocomplete from '@/components/form/vs-form-select.vue'
import VsFormOption from '@/components/form/misc/vs-form-option.vue'
import VsFormSwitch from '@/components/form/vs-form-switch.vue'
import VsFormSwitcher from '@/components/form/vs-form-switch.vue'
import VsFormRadio from '@/components/form/vs-form-radio.vue'
import VsFormTextarea from '@/components/form/vs-form-textarea.vue'
import VsFormDatepicker from '@/components/form/vs-form-datepicker.vue'
import VsFormDatetime from '@/components/form/vs-form-datetime.vue'
import VsFormUpload from '@/components/form/vs-form-upload.vue'
import VsFormFile from '@/components/form/vs-form-file.vue'
import VsFormAce from '@/components/form/vs-form-ace.vue';
import VsDrop from '@/components/basic/vs-drop.vue'
import VsTabs from '@/components/navigations/vs-tabs.vue'
import VsTab from '@/components/navigations/misc/vs-tab.vue'
import VsDialog from '@/components/overlays/dialog/vs-dialog.vue'
import VsDropdown from '@/components/navigations/vs-dropdown.vue'
import VsToast from '@/components/basic/vs-toast.vue'
import VsFormGeom from '@/components/form/geom/vs-form-geom.vue'
import VsFormDatatable from '@/components/form/datatable/vs-form-datatable.vue'
import VsPopover from '@/components/tooltips/vs-popover.vue'

export default {
  VsFormCheckbox, VsFormAce, VsFormText,VsButton,VsForm,VsFormWrapper,VsFormNumber,VsFormColorPicker,VsFormColor,VsFormSelect, VsFormSelect2, VsFormAutocomplete, VsFormOption, VsFormSwitch, VsFormSwitcher, VsFormRadio, VsFormTextarea, VsFormDatepicker, VsFormDatetime, VsFormUpload, VsFormFile, VsDrop, VsTabs, VsTab, VsDialog, VsDropdown, VsToast, VsFormGeom, VsFormDatatable, VsPopover, VsFormRichtext
};