<template>
  <div>
    <div class="flex">
      <div
        class="flex bg-gray-100 hover:bg-gray-200 rounded-lg transition p-1 vst_tabs"
      >
        <nav
          ref="nav"
          class="flex space-x-2"
        />
        <slot name="nav" />
      </div>
    </div>

    <slot />

    <div
      ref="body"
      class="vst-tabs mt-2 bg-white"
    />
  </div>
</template>
<script>
export default {
  props: {
    value: String,
  },
  watch: {
    value(n) {
      this.$emit('input', n);
    },
  },
  mounted() {
    if (!this.value?.toString()) {
      // eslint-disable-next-line
        this.value = this.$children[0].name;
    }
  },
};
</script>
  <style lang="scss" scoped>

  .vst_tabs {
    overflow-y: auto;
    width: 100%;

    &::-webkit-scrollbar {
      height: 6px;
      width: 6px;

      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--primary);
      border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }
  }
  </style>
