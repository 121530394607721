<!-- eslint-disable  -->
<template>
  <button
    @mouseenter="canClose = true"
    @mouseleave="canClose = false"
    @click="toggleDropdown"
    class="w-full text-start vs-form-select relative text-sm"
  >
    <div ref="select" class="h-full">
      <button ref="button" :class="selectClasses" readonly :disabled="disabled" @keydown="handleKeyDown">
        <span v-if="!multiple">{{ formatTitle(selectedLabel) || placeholder }}</span>
        <div v-if="multiple" class="flex flex-wrap gap-1 overflow-y-auto h-full">
          <span v-if="!selectedValues?.length" style="margin-right: 30px" class="text-gray-400">
            {{ placeholder }}
          </span>
          <span v-else> Обрано значень: {{ selectedValues.length }} </span>
        </div>
      </button>

      <div
        ref="list"
        v-show="isDropdownOpen"
        class="vs-select__options mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-md overflow-hidden overflow-y-auto top-full"
      >
        <input
          v-if="localOptions?.length >= 10"
          @keydown="handleKeyDown"
          ref="searchInput"
          v-model="query"
          placeholder="Пошук..."
          class="vstSelect-input-inner text-left bg-white border border-gray-200 text-gray-900 rounded-md focus:ring-blue-500 focus:border-blue-500 block py-2 px-3"
          :class="`text-${this.size}`"
          @click.stop
        />
        <template v-if="query.length">
          <ul ref="optionsList" v-if="filteredOptions.length">
            <li
              v-for="option in filteredOptions"
              :key="formatTitle(option)"
              class="vs-select__options-item cursor-pointer py-2 px-4 w-full text-sm text-gray-800 hover:bg-gray-100 rounded-md focus:outline-none focus:bg-gray-100"
              @click.stop="multiple ? selectOptions(option) : selectOption(option)"
            >
              <div class="flex justify-between items-center w-full">
                {{ formatTitle(option) }}
                <svg
                  v-if="isOptionSelected(option)"
                  class="flex-shrink-0 w-3.5 h-3.5 text-blue-600"
                  xmlns="http:.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
              </div>
            </li>
          </ul>
          <div v-else class="px-4 text-sm text-gray py-2">Співпадінь не знайдено</div>
        </template>
        <ul v-else-if="localOptions?.length" ref="optionsList">
          <li
            v-for="option in localOptions"
            :key="formatTitle(option)"
            class="vs-select__options-item cursor-pointer py-2 px-4 w-full text-sm text-gray-800 hover:bg-gray-100 rounded-md focus:outline-none focus:bg-gray-100"
            :class="{ 'bg-gray-100': JSON.stringify(option) === JSON.stringify(highlightedOption) }"
            @click.stop="multiple ? selectOptions(option) : selectOption(option)"
          >
            <div class="flex justify-between items-center w-full">
              <div class="flex items-center">
                <span v-if="option.color" class="vs-select-color" :style="{ backgroundColor: option.color }"></span>
                <span>{{ formatTitle(option) }}</span>
              </div>
              <svg
                v-if="isOptionSelected(option)"
                class="flex-shrink-0 w-3.5 h-3.5 text-blue-600"
                xmlns="http:.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="20 6 9 17 4 12" />
              </svg>
            </div>
          </li>
        </ul>
        <div v-else class="px-4 text-sm text-gray py-2">Немає даних</div>
      </div>
    </div>
    <i class="vs-select__arrow ti ti-chevron-down shrink-0 text-gray-500" :class="{ active: isDropdownOpen }"></i>
    <i
      ref="clearBtn"
      v-if="(clearable && selectedLabel) || (clearable && selectedValues.length)"
      @click.stop="clearSelect"
      class="vs-select__clear ti ti-x shrink-0 text-gray-500"
    ></i>
  </button>
</template>

<script>
import filterElementMx from '@/components/form/mixins/vs-form-mx';

export default {
  mixins: [filterElementMx],
  props: {
    value: { type: [String, Number], default: '' },
    data: { type: String, default: '' },
    api: { type: String, default: '/api-user/suggest/' },
    appendToBody: { type: Boolean, default: false },
    placeholder: { type: String, default: 'Виберіть значення..' },
  },
  data() {
    return {
      isDropdownOpen: false,
      selectedValues: [],
      selectedLabel: '',
      canClose: false,
      query: '',
      highlightedOption: 0,
      localOptions: null,
    };
  },
  computed: {
    selectClasses() {
      return [
        'vstSelect-input transition-all text-left bg-white border border-gray-200 rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full h-full py-2 px-3',
        { [`!text-${this.size}`]: this.size !== 'md' },
        { 'text-base': this.size === 'md' },
        { [this.disabled ? '!cursor-not-allowed' : '!cursor-pointer']: true },
        { 'text-gray-400': !this.selectedLabel && !this.multiple },
        { 'text-gray-900': (this.selectedLabel && !this.multiple) || (this.selectedValues.length && this.multiple) },
      ];
    },
    filteredOptions() {
      const data = this.localOptions?.filter((option) =>
        this.formatTitle(option).toLowerCase().includes(this.query.toLowerCase())
      );

      return data;
    },
  },
  watch: {
    isDropdownOpen(n) {
      if (n) {
        this.$nextTick(() => {
          this.$refs?.searchInput?.focus();
          this.setBoundsToList();
        });
      } else {
        this.query = '';
        this.highlightedOption = null;
      }
    },
  },
  mounted() {
    this.getInitialValue();

    window.addEventListener('resize', this.setBoundsToList);
    window.addEventListener('scroll', this.setBoundsToList);
    window.addEventListener('click', this.closeDropdownOnClickOutside, false);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setBoundsToList);
    window.removeEventListener('scroll', this.setBoundsToList);
    window.removeEventListener('click', this.closeDropdownOnClickOutside);
  },
  methods: {
    formatTitle(option) {
      return option?.text || option?.title || option?.label || option?.id || option?.key || option;
    },
    removeOption(option) {
      this.selectedValues = this.selectedValues.filter((el) => JSON.stringify(el) !== JSON.stringify(option));
      this.emitMultiple();
    },
    closeDropdownOnClickOutside() {
      if (!this.canClose) {
        this.isDropdownOpen = false;
      }
    },
    isOptionSelected(option) {
      const labelEqual = this.selectedLabel === this.formatTitle(option);
      const isOptionEqual = this.selectedValues.find((el) => JSON.stringify(el) === JSON.stringify(option));

      return labelEqual || isOptionEqual;
    },
    clearSelect() {
      if (this.multiple) {
        this.selectedValues = [];
        this.emitMultiple();
      } else {
        this.selectedLabel = '';
        this.$emit('change', '');
        this.$emit('input', '');
      }
    },
    handleKeyDown(event) {
      switch (event?.key) {
        case 'ArrowUp':
          event.preventDefault();
          this.highlightOption(-1);
          break;
        case 'ArrowDown':
          event.preventDefault();
          this.highlightOption(1);
          break;
        case 'Enter':
          event.preventDefault();
          this.selectHighlightedOption();
          break;
        case 'Escape':
          this.isDropdownOpen = false;
          this.$refs.select.blur();
          break;
      }
    },
    scrollToHighlightedOption() {
      const optionsList = this.$refs?.optionsList;

      if (!optionsList || !optionsList.childNodes.length) return;

      const index = this.filteredOptions.findIndex(
        (option) => JSON.stringify(option) === JSON.stringify(this.highlightedOption)
      );

      const optionRef = optionsList.childNodes[index];

      if (optionRef) {
        optionRef.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        });
      }
    },
    highlightOption(direction) {
      const currentIndex = this.filteredOptions.findIndex(
        (option) => JSON.stringify(option) === JSON.stringify(this.highlightedOption)
      );

      let nextIndex = currentIndex + direction;

      if (nextIndex < 0) {
        nextIndex = 0;
      } else if (nextIndex >= this.filteredOptions.length) {
        return;
      }

      this.highlightedOption = this.filteredOptions[nextIndex];
      this.scrollToHighlightedOption();
    },
    selectHighlightedOption() {
      const selectedOption = this.filteredOptions.find(
        (option) => JSON.stringify(option) === JSON.stringify(this.highlightedOption)
      );
      if (selectedOption) {
        this.multiple ? this.selectOptions(selectedOption) : this.selectOption(selectedOption);
      }
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      this.setBoundsToList();
    },
    selectOption(option) {
      if (this.isOptionSelected(option)) {
        this.resetSelection();
      } else {
        const value = option[this.getOptionValue()] || option;
        this.updateSelection(value, option);
      }

      if (!this.multiple) {
        this.isDropdownOpen = false;
      }
    },
    resetSelection() {
      this.selectedLabel = '';
      this.emitChangeAndInput('');
    },
    updateSelection(value, option) {
      this.selectedLabel = this.formatTitle(option);
      this.emitChangeAndInput(value);
    },
    emitChangeAndInput(value) {
      this.$emit('change', value);
      this.$emit('input', value);
    },
    selectOptions(option) {
      if (!this.selectedValues.includes(option)) {
        this.selectedValues = [...this.selectedValues, option];
      } else {
        this.selectedValues = this.selectedValues.filter(
          (el) => el[this.getOptionValue()] !== option[this.getOptionValue()] || el !== option
        );
      }
      this.emitMultiple();
    },
    emitMultiple() {
      const value = this.selectedValues
        .map((el) => (typeof el === 'object' ? el[this.getOptionValue(el)] : el))
        .join(',');
      this.$emit('change', value);
      this.$emit('input', value);
    },
    async getSuggestData() {
      const resp = await fetch(`${this.api}${this.data}?limit=50`);

      const data = await resp.json();
      return data;
    },
    async getInitialValue() {
      this.localOptions = this.options;

      if (this.data.length) {
        this.localOptions = await this.getSuggestData();
      }

      if (this.localOptions?.length) {
        if (this.multiple) {
          const values = this.value.split(',');
          this.selectedValues = this.localOptions?.filter((option) =>
            values.includes(option?.[this.getOptionValue()]?.toString() || option)
          );
        }

        const currentItem = this.localOptions?.find(
          (option) => option[this.getOptionValue()] == this.value || option == this.value
        );

        this.selectedLabel = this.formatTitle(currentItem);
      }

      if (this.$slots.default) {
        if (this.multiple) {
          const valueData = typeof this.value === 'string' ? this.value.split(',') : this.value;

          this.selectedValues = valueData.map((el) => {
            const currentOption = this.$slots.default.find(
              (component) => component.componentOptions.propsData.value === el
            );

            if (!currentOption) return el;

            return currentOption.componentOptions.propsData;
          });
        } else {
          const currentItem = this.$slots.default.find(
            (component) => component?.componentOptions?.propsData?.value === this.value
          );

          this.selectedLabel = currentItem?.componentOptions?.propsData?.label || '';
        }
      }
    },
    setBoundsToList() {
      if (this.isDropdownOpen) {
        const { list } = this.$refs;
        if (this.appendToBody) {
          document.body.prepend(list);
        }
        const { button } = this.$refs;

        this.$nextTick(() => {
          const buttonParams = button.getBoundingClientRect();
          const listParams = list.getBoundingClientRect();

          const bottomSpace = window.innerHeight - buttonParams.bottom;

          if (bottomSpace < listParams.height) {
            list.style.top = `${buttonParams.top - listParams.height - 15}px`;
          } else {
            list.style.top = `${buttonParams.bottom}px`;
          }

          list.style.width = `${buttonParams.width}px`;
          list.style.left = `${buttonParams.left}px`;
        });
      }
    },
    getOptionValue() {
      return 'id' || 'value' || 'val';
    },
  },
};
</script>

<style lang="scss" scoped>
.vs-form-select {
  overflow-y: hidden;
}

input {
  outline-color: var(--primary);
}
.vs-select {
  &__arrow {
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;

    &.active {
      transform: rotate(180deg) translateY(50%);
    }
  }
  &__clear {
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
  }
  &__options {
    // max-height: 250px;
    overflow-y: auto;
    z-index: 1000000;
    position: fixed;
  }
  &-color {
    width: 15px;
    height: 15px;
    border-radius: 3px;
    margin-right: 10px;
  }
}
.vstSelect-input {
  padding-right: 45px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;

  &:focus-visible {
    outline: none;
  }
}

.vstSelect-input-inner {
  width: 98%;
  margin: 5px auto 0px;
  display: block;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.vs-select:focus + .absolute {
  display: block;
}
</style>
