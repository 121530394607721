var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"VstCheckbox"},[(!_vm.checkboxBody && !_vm.options.length)?[_c('div',{ref:"checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modelValue),expression:"modelValue"}],class:_vm.checkboxClasses,attrs:{"id":_vm.inputId,"type":"checkbox","disabled":_vm.disabled},domProps:{"value":_vm.label,"checked":Array.isArray(_vm.modelValue)?_vm._i(_vm.modelValue,_vm.label)>-1:(_vm.modelValue)},on:{"change":[function($event){var $$a=_vm.modelValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.label,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.modelValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.modelValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.modelValue=$$c}},function($event){return _vm.$emit('change', $event.target.checked)}]}}),(_vm.label)?_c('label',{class:[
          'vs-checkbox-label text-gray-500 font-normal mb-0 ml-3',
          { 'text-xs': _vm.size === 'xs' },
          { 'text-sm': _vm.size === 'default' },
          { 'text-md': _vm.size === 'md' },
          { 'text-lg': _vm.size === 'lg' },
          { 'text-xl': _vm.size === 'xl' },
          { 'cursor-pointer': !_vm.disabled },
          { 'cursor-not-allowed': _vm.disabled },
        ],attrs:{"for":_vm.inputId},on:{"click":function($event){$event.stopPropagation();}}},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.label))]})],2):_vm._e()])]:_vm._e(),(_vm.options.length)?[_c('div',{staticClass:"flex flex-col gap-2"},_vm._l((_vm.options),function(option){return _c('div',{key:_vm.formatOption(option),staticClass:"flex items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedOptionsInternal),expression:"selectedOptionsInternal"}],class:_vm.checkboxClasses,attrs:{"id":_vm.inputId + '-' + _vm.formatOption(option),"type":"checkbox","disabled":_vm.disabled},domProps:{"value":_vm.formatOption(option),"checked":Array.isArray(_vm.selectedOptionsInternal)?_vm._i(_vm.selectedOptionsInternal,_vm.formatOption(option))>-1:(_vm.selectedOptionsInternal)},on:{"change":[function($event){var $$a=_vm.selectedOptionsInternal,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.formatOption(option),$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selectedOptionsInternal=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selectedOptionsInternal=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selectedOptionsInternal=$$c}},function($event){return _vm.toggleCheckbox($event)}],"input":function($event){return _vm.toggleCheckbox($event)}}}),_c('label',{staticClass:"vs-checkbox-label text-gray-500 ml-3 font-normal mb-0",class:[
            { 'cursor-pointer': !_vm.disabled },
            { 'cursor-not-allowed': _vm.disabled }
          ],attrs:{"for":_vm.inputId + '-' + _vm.formatOption(option)}},[_vm._v(" "+_vm._s(_vm.formatOption(option))+" ")])])}),0)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }