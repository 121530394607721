<template>
  <div class="map-search">
    <div class="map-search__input">
      <div class="flex flex-nowrap ">
        <select v-model="searchType" class="flex-shrink-0 inline-flex items-center py-2.5 focus:ring-blue-100 focus:border-blue-100 outline-none text-sm font-medium text-gray-500  focus:outline-none	 border border-gray-300 rounded-s-md hover:bg-gray-100 cursor-pointer"> 
          <option v-for="(item,index) in searchOptions" :key="index" :value="item.id">{{ item.name }}</option>
        </select>
        <div class="map-search__input-inner w-full">
          <input v-model="searchable" :placeholder="placeholder" class="w-full block p-2.5 w-full focus:ring-blue-100 focus:border-blue-100 outline-none text-sm text-gray-900 rounded-e-md border-s-gray-50 border-s-2 focus:outline-none  border border-gray-300 placeholder-gray-300" @change="searchOnMap" />
          <i v-if="searchable" class="ti ti-x map-search__input-close" @click="searchable = null"></i>
        </div>
      </div>
      <div v-if="searchData?.[0]?.text" class="map-search__input-list" :style="{'max-height':height }">
        <div v-for="(item,index) in searchData" v-show="searchData" :key="index" class="map-search__input-list__item" @click="zoomToGeom(item);">
          {{ item.text }}
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
  import turf from "turf";
  import axios from 'axios'

  export default {
    props: {
        map: { type: Object, default: () => { } },
        height: {type: String, default: () => '400px'},
    },
    data() {
        return {
          searchable: '',
          searchType: 'atu',
         
          searchOptions: [
            {
                name: 'Коорд.',
                placeholder:'50.44920, 30.52359',
                id:'coords'
              },
              {
                name: 'Адрес',
                placeholder:'Шевченка, 10',
                id:'address'
              },
              {
                name: 'АТУ',
                placeholder:'Київська область',
                id:'atu'
              },
              {
                name: 'Here',
                placeholder:'50.44920, 30.52359',
                id:'here'
              }
            ],
            marker: null,
            placeholder:'Київська область',
            searchData:null
        }
    },
    watch: {
      searchType() {
          this.searchable = '';
          this.searchData = null;
          this.placeholder = this.searchOptions.find(el=>el.id === this.searchType).placeholder;
      },
      // When the text value changes, marker will remove :) 
      searchable() { 
        if (this.marker) this.marker.remove()
      }
    },
    methods: {
      // Conditional function for call the method which will interact with the map
        async searchOnMap() {
          this.searchData = null;
          switch (this.searchType) {
            case 'address': {
              this.searchAddress()
              break;
            }
            case 'atu': {
              this.searchAtu()
              break;
            }
            case 'here': {
              this.searchHere()
              break;
            }
            case 'coords': {
              this.searchCoords()
              break;
            }  
          }
      },
      // Zoom to geometry by arg item.geom if it exists
      async zoomToGeom(item) {
        if (item.text) this.searchable = item.text;
        
        const geomIsString = typeof item.geom === 'string'
        const geom = geomIsString ? JSON.parse(item.geom) : item.geom

        const bbox = turf.bbox(geom);
        await this.map.fitBounds(bbox, { duration: 0, maxZoom: 17, padding: 30 });
        this.searchData = null;
        await this.createMarker(geom)
      },
      // Creates a simple marker on the map by geom arg.
      createMarker(geom) { 
        const { geometry } = turf.center(geom)
        const center = geometry.coordinates
        this.marker = new window.maplibregl.Marker({color: '#3b82f6'}).setLngLat(center).addTo(this.map)
      },
      // Simple method for request by url with error handling 
      async requestData(url) { 
        try {
          const { data } = await axios.get(url);

          return data
        } catch (error) {
          console.error(error);
        }
      },
      // Get coordinates from input for create a marker 
      searchCoords() { 

        // Method splits and replaces all spaces and also make trim for value 
        const formatCords = (crd) => {
          const value = crd.replaceAll(' ', '').trim();

          if (value.includes('.') || value.length <= 2) return value;

          // Here function slices value if value has no point and value less than 2
          const first = value.slice(0, 2);
          const second = value.slice(2, value.length - 1);

          return `${first}.${second}`;
        };

        const coords = this.searchable.split(',').map((el) => +formatCords(el));
        const point = turf.point(coords.reverse())

        this.zoomToGeom({geom: point})
      },
      // Search by address at Ukraine
      async searchAddress() { 
        const data = await this.requestData(`/api-user/suggest/map.address_id?limit=20&key=${this.searchable}`);

        this.searchData = data.map(address => ({...address, geom: turf.point([address.x, address.type])}))
      },
      // Search by here api like the address but... it's different idk :) 
      async searchHere() { 
        this.searchData = await this.requestData(`https://data.softpro.ua/api-user/gis-url-proxy?searchtext=${this.searchable}`);

        // Converte Object to Array for create a point object
        const coordinates = Object.values(this.searchData?.Location?.DisplayPosition || {}).reverse()
        const point = turf.point(coordinates)

        this.zoomToGeom({geom: point})
      },
      // Get data via atu api
      async searchAtu() {
        this.searchData = await this.requestData(`https://data.softpro.ua/api-user/suggest/atu.nsdi.ato_level?key=${this.searchable}`);
      }
    }
  };
 
  </script>
  <style lang="scss" scoped>
    .map-search{
      width: 350px;
      &__input{
        &-inner{
          input{
            padding-right: 25px;
          }
        }
        &-close{
          position: absolute;
          cursor: pointer;
          right: 10px;
          top:50%;
          transform: translateY(-50%);
        }
        &-list{
          position: absolute;
          top:50px;
          z-index: 1;
          background: #fff;
          overflow-y: auto;
          width:calc(100% - 25px);
          &__item{
            padding: 5px 10px;
            font-size: 14px;
            cursor: pointer;
            &:hover{
              background: #EBEBEB;
            }
          }
        }
      }
    }
  </style>
  