<template>
  <div class="datatable">
    <div class="flex items-center justify-between gap-5 pb-2">
      <VsFormText v-model="userSearch" placeholder="Пошук" size="xs" icon="ti ti-search" class="w-full" />
      <div class="flex items-center gap-2">
        <TableFormInput :value.sync="dataset" :input.sync="keysMeta" />
        <VsButton type="primary" size="mini" @click="openAddForm">Додати</VsButton>
      </div>
    </div>
    
    <div class="table-responsive">
      <table class="border-collapse border border-slate-300 w-full">
        <thead class="z-999 border-b-0 sticky top-0 bg-slate-50">
          <tr>
            <th class="border border-slate-300 font-semibold p-2 text-slate-900 text-left">#</th>
            <th v-for="column in keysMeta" :key="column.name" class="border border-slate-300 font-semibold p-2 text-slate-900 text-left">
              {{ column.ua }}
            </th>
            <th class="border border-slate-300 font-semibold p-2 text-slate-900 text-left">Змінити</th>
          </tr>
        </thead>
        
        <template v-if="filteredDataset.length">
          <tr v-for="(row, rowIndex) in filteredDataset" v-show="!row.hide" :key="rowIndex">
            <td class="border border-slate-300 p-2 text-slate-500">{{ rowIndex + 1 }}</td>
            <td v-for="el in keysMeta" :key="el.name" class="border border-slate-300 p-2 text-slate-500">
              <VsFormCheckbox v-if="el.type === 'Switcher'" v-model="row[el.key]"></VsFormCheckbox>
              <template v-else>
                <span v-if="['Select2', 'Select', 'Autocomplete'].includes(el.type)">
                  {{ row[`${el.key}_suggest`] }}
                </span>

                <span v-else>
                  {{ row[el.key] || '-' }}
                </span>
              </template>
            </td>
            <td class="border border-slate-300 p-2 text-slate-500">
              <div class="flex items-center justify-center gap-2">
                <VsButton square type="primary" size="mini" icon="ti ti-pencil" @click="startEdit(row)"></VsButton>
                <VsButton square type="primary" size="mini" icon="ti ti-trash" @click="handleDelete(row)"></VsButton>
              </div>
            </td>
          </tr>
        </template>

        <p v-else class="p-2">Немає даних для відображення.</p>
      </table>
    </div>

    <VsDialog :visible.sync="isDialogVisible" :title="dialogTitle">
      <VsForm v-model="newElement" class="p-4" :scheme="keysMeta" />
      <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t">
        <button
          type="button"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50"
          @click="isDialogVisible = false"
        >
          Відмінити
        </button>
        <button
          type="button"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700"
          @click="handleConfirm"
        >
          Зберегти
        </button>
      </div>
    </VsDialog>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  components: {
    VsDialog: () => import('../../overlays/dialog/vs-dialog.vue'),
    VsForm: () => import("../vs-form.vue"),
    TableFormInput: () => import('./vs-form-datatable-input.vue'),
    VsButton: () => import('../../basic/vs-button.vue'),
    VsFormText: () => import('../vs-form-text.vue'),
    VsFormCheckbox: () => import('../vs-form-checkbox.vue'),
  },
  props: {
    colModel: { type: Array, default: () => [] },
    value: { default: () => [] },
  },
  data() {
    return {
      userSearch: '',
      dialogType: '',
      newElement: {},
      currentlyEditing: null,
      keysMeta: [],
      dataset: [],
      selectKeys: [],
      isDialogVisible: false,
    };
  },
  computed: {
    dialogTitle() {
      return this.dialogType === 'add' ? 'Додати елемент' : 'Редагувати елемент';
    },
    filteredDataset() {
      return this.dataset.filter(el => !el.hide);
    },
  },
  watch: {
    dataset: {
      handler(n) {
        this.$emit('change', n);
      },
      deep: true,
    },
    userSearch(n) {
      this.dataset.forEach(el => {
        el.hide = !Object.values(el).join('').toLowerCase().includes(n.toLowerCase());
      });
    },
    isDialogVisible(n) {
      if (!n) {
        this.newElement = {};
      }
    }
  },
  mounted() {
    this.keysMeta = this.colModel
      .filter(el => el.type)
      .map(el => ({ ...el, key: el?.name || el.key, label: el.ua }));
    this.dataset = this.value || [];
    this.selectKeys = this.keysMeta
      .filter((obj) => ['Select2', 'Select', "Autocomplete"].includes(obj.type))
      .map((el) => ({ key: el.key, data: el.data }));
  },
  methods: {
    handleDelete(el) {
      this.dataset = this.dataset.filter(element => element !== el);
    },
    openAddForm() {
      this.dialogType = 'add';
      this.isDialogVisible = true;
    },
    async handleConfirm() {
    if (this.dialogType === 'add') {
      const newElementObj = {};
      
      // Перетворюємо кожну властивість в окремий об'єкт
      for (const key in this.newElement) {
        newElementObj[key] = this.newElement[key];
      
        // Перевірка, чи не є поле саджестом
        const selectKey = this.selectKeys.find(selectKey => selectKey.key === key);

        // Якщо є саджест, то шукаємо його текст по id
        if (selectKey) {
          const suggestTextResult = await this.getSuggestText(this.newElement[key], selectKey.data);
          newElementObj[`${selectKey.key}_suggest`] = suggestTextResult;
        }
      }
      
      this.dataset.push(newElementObj);
    } else if (this.dialogType === 'edit') {
      // Тут також оновлюємо кожну властивість окремим об'єктом
      this.dataset = await Promise.all(this.dataset.map(async (el) => {
        if (el === this.currentlyEditing) {
          const updatedProperties = await this.updateProperties(this.newElement);
          return updatedProperties;
        } else {
          return el
        }
      }));
      this.currentlyEditing = null;
    }
    this.dialogType = '';
    this.isDialogVisible = false
    },
    async updateProperties(newObj) {
      const updatedObj = {};

      for (const key in newObj) {
        if (key.includes('_suggest')) {
          continue;
        }
        updatedObj[key] = newObj[key];

         // Перевірка, чи не є поле саджестом
        const selectKey = this.selectKeys.find(selectKey => selectKey.key === key);

        if (selectKey) {
          const suggestTextResult = await this.getSuggestText(newObj[key], selectKey.data);
          updatedObj[`${selectKey.key}_suggest`] = suggestTextResult;
        }
      }

      return updatedObj;
    },
    startEdit(el) {
      this.newElement = JSON.parse(JSON.stringify(el));
      this.currentlyEditing = el;
      this.dialogType = 'edit';
      this.isDialogVisible = true;
    },
    async getSuggestText(suggestVal, suggest) {
      try {
        const { data } = await axios.get(`/api-user/suggest/${suggest}`, {
          params: {
            val: suggestVal,
          },
        });

        return data[0].text;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
