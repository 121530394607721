<template>
  <div class="flex gap-2">
    <VsFormSelect
      v-model="selectedDay"
      :options="dayOptions"
      placeholder="День"
      :disabled="disabled"
    />
    <VsFormSelect
      v-model="selectedMonth"
      :options="monthOptions"
      placeholder="Місяць"
      :disabled="disabled"
    />
    <VsFormSelect
      v-model="selectedYear"
      :options="yearOptions"
      placeholder="Рік"
      :disabled="disabled"
    />
  </div>
</template>

<script>
export default {
  components: { VsFormSelect: () => import('./vs-form-select.vue') },
  props: {
    yearRange: {
      type: Number,
      default: 10,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const currentYear = new Date().getFullYear();

    return {
      days: Array.from({ length: 31 }, (_, i) => i + 1),
      months: ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'],
      years: Array.from({ length: 10 }, (_, i) => currentYear + i),
      selectedDay: null,
      selectedMonth: null,
      selectedYear: null,
      startYear: currentYear,
    };
  },
  computed: {
    dayOptions() {
      return this.days.map((day) => ({ label: day, value: day }));
    },
    monthOptions() {
      return this.months.map((month, i) => ({ label: month, value: i }));
    },
    yearOptions() {
      return Array.from({ length: this.yearRange }, (_, i) => ({
        label: this.startYear + i,
        value: this.startYear + i,
      }));
    },
  },
  watch: {
    selectedDay: 'emitDate',
    selectedMonth: 'emitDate',
    selectedYear: 'emitDate',
  },
  methods: {
    emitDate() {
      if (this.selectedDay !== null && this.selectedMonth !== null && this.selectedYear !== null) {
        const selectedDate = new Date(this.selectedYear, this.selectedMonth, this.selectedDay);
        this.$emit('change', selectedDate);
      }
    },
  },
};
</script>
