<template>
  <div v-if="legendData.length" class="legend">
    <div v-for="legend in legendData" :key="legend.name" class="legend__wrapper">
      <div
        class="legend__title cursor-pointer"
        :class="{ 'active': legend.visible }"
        @click="toggleLegend(legend)"
      >
        {{ legend.name }}
      </div>
      <template v-if="legend.visible">
        <div v-for="(row, index) in legend.data" :key="index" class="legend__item">
          <div class="legend__color" :style="'background-color:' + row.color + ';'" />
          <div :title="row.text" class="legend__label">
            {{ row.text }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dataset: { type: Array, default: () => [] },
    layerList: { type: Array, default: () => [] },
  },
  data() {
    return {
      legendData: [],
    };
  },
  watch: {
    layerList() {
      this.getActiveLayers();
    },
  },
  methods: {
    getLegendData(layer) {
      const attr = layer?.attrFill || layer.style.colorAttr;
      const metaColumns = layer?.meta?.columns || [];
      const currentColumn = metaColumns.find((column) => column.name === attr);

      const legendData = currentColumn.values.map((value) => ({
        ...value,
        color: value?.color || "#eee",
      }));

      return legendData;
    },
    getActiveLayers() {
      const datasetLayers = this.dataset.map((layer) => layer?.id || layer.data);

      const aviableLayers = this.layerList.reduce((prev, layer) => {
        if (!datasetLayers.includes(layer.data) || !layer.visible) return prev;

        const existLegend = this.legendData.find((legend) => legend?.name === layer?.name);

        if (existLegend) return prev.concat(existLegend);

        const layerInfo = {
          name: layer?.name || layer.title,
          visible: false,
          id: Math.floor(Math.random() * 1000),
          data: this.getLegendData(layer),
        };

        return prev.concat(layerInfo);
      }, []);

      this.legendData = aviableLayers;
    },
    toggleLegend({ id }) {
      this.legendData = this.legendData.map((legend) =>
        legend.id === id ? { ...legend, visible: !legend.visible } : legend
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.legend {
  position: absolute;
  background-color: white;
  border-radius: 5px;
  bottom: 10px;
  left: 10px;
  max-width: 220px;
  max-height: 250px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #b5b5b5;
  }
  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }

  &__wrapper {
    padding: 10px;
    font-size: 13px;
    &:not(:last-child) {
      border-bottom: 1px solid #d0d0d0;
    }
  }

  &__title {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    font-weight: 600;
    &.active{
      text-wrap: balance;
      margin-bottom: 5px;
    }
  }
  &__label{
    font-weight: 400;
  }

  &__color {
    width: 12px;
    height: 12px;
    border-radius: 3px;
    margin-right: 7px;
  }

  &__item {
    display: flex;
    align-items: center;
  }

}
</style>
