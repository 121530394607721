var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative"},[(_vm.label)?_c('label',{staticClass:"mb-2 text-gray-500 cursor-pointer",attrs:{"for":"message"}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.vModel),expression:"vModel"}],staticClass:"py-3 px-4 transition-all pr-11 block w-full border border-gray-200 rounded-md focus:border-blue-500 focus:ring-blue-500",class:{
      'text-xs': _vm.size === 'mini',
      'text-sm': _vm.size === 'default',
      'text-lg': _vm.size === 'large',
      'cursor-not-allowed opacity-50': _vm.disabled,
      'cursor-pointer': !_vm.disabled,
    },attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"rows":_vm.rows,"cols":"70"},domProps:{"value":(_vm.vModel)},on:{"input":function($event){if($event.target.composing)return;_vm.vModel=$event.target.value}}}),_vm._v(" "),_c('transition',{attrs:{"name":"slide"}},[(_vm.clearable && _vm.vModel)?_c('div',{staticClass:"text-color-gray-50 cursor-pointer text-sm absolute right-2 top-2",on:{"click":_vm.clearInput}},[_c('i',{staticClass:"ti ti-x"})]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }