<!-- eslint-disable max-len -->
<template>
  <transition name="slide-fade">
    <div v-if="visible" ref="modalOverlay" class="vst-modal">
      <div class="vst-modal-bg" @click="handleOverlayClick" />
      <div ref="modalBody" class="vst-modal-window">
        <div
          class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto"
        >
          <div class="flex justify-between items-center py-3 px-4 border-b">
            <div class="font-bold text-gray-800 text-xl">
              {{ title }}
            </div>
            <button
              v-if="cancel"
              type="button"
              class="flex justify-center items-center w-7 h-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100"
              @click="$emit('update:visible', false)"
            >
              <svg
                class="flex-shrink-0 w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M18 6 6 18" />
                <path d="m6 6 12 12" />
              </svg>
            </button>
          </div>
          <div v-if="message" class="px-4 pt-4">{{ message }}</div>
          <slot>
            <slot name="content"> </slot>
            <slot name="footer">
              <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t">
                <button
                  type="button"
                  class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50"
                  @click="handleClose"
                >
                  Відмінити
                </button>
                <button
                  type="button"
                  class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700"
                  @click="handleSubmit"
                >
                  Зберегти
                </button>
              </div>
            </slot>
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  components: {},
  props: {
    title: { type: String, default: "" },
    visible: { type: Boolean, default: false },
    message: { type: String, default: "" },
    cancel: { type: Boolean, default: true },
    alert: { type: String, default: "" },
    persistent: { type: Boolean, default: true },
  },
  data() {
    return {
      
    };
  },
  watch: {
    visible(n) {
      if (n) {
        this.$nextTick(() => {
          document.body.append(this.$refs.modalOverlay);
        });
      } 
    },
  },
  methods: {
    handleClose() {
      this.$emit("update:visible", false);
    },
    handleSubmit() {
      this.$emit("update:visible", false);
      this.$emit("submit");
    },
    // якщо клік за межами діалогового вікна, то закрити його
    handleOverlayClick() {
      if (!this.persistent) {
        this.handleClose();
      }
    },
  },
};
</script>
<style lang="scss">
.vst {
  &-modal {
    left: 0;
    top: 0;
    z-index: 10000;
    width: 100%;
    height: 100%;
    position: fixed;

    &-window {
      left: 50%;
      top: -60%;
      position: relative;
      transform: translate(-50%, -50%);
      width: 60%;
    }

    &-body {
      max-height: 600px;
      overflow-y: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 6px;
        background-color: #f5f5f5;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: var(--primary);
      }
      &::-webkit-scrollbar-track {
        background-color: #f5f5f5;
      }
    }

    &__title {
      max-width: 90%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-box {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -60%);
      z-index: 10001;
    }

    &-bg {
      width: 100%;
      height: 100%;
      opacity: 0.3;
      background-color: black;
    }
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: scale(1.02);
  opacity: 0;
}
</style>
