<template>
  <div>
    <div class="form-geom">
      <div :id="mapId" :style="{height}" />
      <VsFormGeomLegend :dataset="dataset" :layer-list="layerList" />
      <VsMapSearch :map.sync="map" :height="height" class="form-geom__search" />
      <div class="form-geom__panel">
        <VsFormGeomFilesTransfer :draw="draw" :map="map" @upload="drawEvent" />
      </div>
      <div class="form-geom__tools flex flex-col">
        <div
          title="Вирівняти карту"
          class="form-geom__tool py-0.5 px-2 cursor-pointer transition-all border rounded-md text-sm bg-white hover:bg-blue-500"
          @click="mapLevelOut"
        >
          <i class="ti ti-compass"></i>
        </div>
        <div
          v-for="tool in drawTools"
          :key="tool.mode"
          :title="tool.title"
          class="form-geom__tool py-0.5 px-2 cursor-pointer transition-all border rounded-md text-sm bg-white hover:bg-blue-500 hover:text-white"
          :class="{ '!bg-blue-500 !text-white': tool.mode === activeTool }"
          @click="toolClick(tool)"
        >
          <i :class="'ti ' + tool.icon" />
        </div>
        <div class="relative">
          <div
            title="Шари"
            class="form-geom__tool py-0.5 px-2 cursor-pointer transition-all border rounded-md text-sm bg-white hover:bg-blue-500"
            :class="{ '!bg-blue-500': showLayers }"
            @click="showLayers = !showLayers"
          >
            <i class="ti ti-stack-2" :class="{ '!text-white': showLayers }" />
          </div>

          <VsFormGeomLayers
            v-if="map"
            v-show="showLayers"
            :api-layer="apiLayer"
            :layers="layers"
            :dataset="dataset"
            :map="map"
          />
        </div>
      </div>
    </div>
    <transition-group name="list" :duration="300" tag="div" class="form-geom__features">
      <VsFormGeomFeature
        v-for="feature in featuresList"
        :key="feature.id"
        class="form-geom__feature flex items-center"
        :draw="draw"
        :data="feature"
        :selected-features="selectedFeatures"
        :map="map"
        @delete="deleteFeature(feature)"
      />
    </transition-group>
  </div>
</template>

<script>
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import turf from "turf";
import DrawRectangle from "mapbox-gl-draw-rectangle-mode";
import VsMapSearch from "./components/vs-map-search.vue";
import layerMixins from "./map/utils/layerMixins";
import VsFormGeomLayers from "./vs-form-geom-layers.vue";
import VsFormGeomFilesTransfer from "./vs-form-geom-files-transfer.vue";
import VsFormGeomFeature from "./vs-form-geom-feature.vue";
import head from "@/vendor/head.js";
import VsFormGeomLegend from "./vs-form-geom-legend.vue";

export default {
  components: {
    VsMapSearch,
    VsFormGeomLayers,
    VsFormGeomFeature,
    VsFormGeomFilesTransfer,
    VsFormGeomLegend,
  },
  mixins: [layerMixins],
  props: {
    height: {type: String, default: () => '400px'},
    value: { type: [Object, Array], default: () => null },
    multiple: { type: Boolean, default: () => false },
    multipleAll: { type: Boolean, default: () => false },
    apiLayer: { type: String, default: () => "" },
    layers: {
      type: Array,
      default: () => [],
    },
    dataset: { type: Array, default: () => [] },
    tools: { type: [Array], default: () => ["polygon"] },
    zoom: { type: Number, default: () => 10 },
    center: { type: Array, default: () => [30.5, 50.45] },
    maxZoom: {type: Number, default: () => 21},
    minZoom: {type: Number, default: () => 0}
  },
  data() {
    return {
      mapId: `map-${Math.floor(Math.random() * 1000)}`,
      map: null,
      draw: null,
      activeTool: "",
      showLayers: false,
      drawTools: [
        {
          title: 'Полігон',  
          mode: "draw_polygon",
          icon: "ti-polygon",
        },
        {
          title: 'Точка',  
          mode: "draw_point",
          icon: "ti-map-pin",
        },
        {
          title: 'Лінія',  
          mode: "draw_line_string",
          icon: "ti-line",
        },
        {
          title: 'Полігон (квадрат)',  
          mode: "draw_rectangle",
          icon: "ti-topology-ring-3",
        },
      ],
      selectedFeatures: [],
      featuresList: [],
    };
  },
  watch: {
    activeTool(n) {
      const canvas = this.map.getCanvas();

      canvas.style.cursor = n ? 'crosshair' : 'grab' 
    },
  },
  async mounted() {
    await head.promise([
      "https://cdn.softpro.ua/vendor/maplibre/maplibre.js",
      "https://cdn.softpro.ua/vendor/maplibre/maplibre.css",
    ]);

    this.initMap();
    this.drawTools = this.drawTools.filter((tool) => this.tools.find((el) => tool.mode.match(el)));
  },
  beforeDestroy() {
    this.map.off("draw:create", this.drawCreateEvent);
    this.map.off("draw:update", this.drawEvent);
    this.map.off("draw:delete", this.drawEvent);
    window.removeEventListener('keydown', this.keyboardEvents)
  },
  methods: {
    // Function is setup the map in state
    async initMap() {
      // eslint-disable-next-line
      const map = new maplibregl.Map({
        container: this.mapId,
        style: {
          version: 8,
          sources: {},
          layers: [],
        },
        center: this.center,
        zoom: this.zoom,
        maxZoom: this.maxZoom,
        minZoom: this.minZoom
      });

     

      map.on("load", this.onMapLoad);
    },
    initDrawTool() {
      const { modes } = MapboxDraw;
      modes.draw_rectangle = DrawRectangle;

      this.draw = new MapboxDraw(
        { 
          displayControlsDefault: false,
          controls: {
          polygon: true,
          trash: true
          },
          modes 
        }
      );

      this.map.on('click', this.handleMapClick)
      this.map.on('moveend', this.handleMapClick)

      this.map.addControl(this.draw);

      // This event sets in data component but it shouldn't be assign in global view
      this.drawCreateEvent = async () => {
        this.activeTool = "";
        await this.drawEvent();
        await this.getAllSelectedFeatures()
      };

      this.map.on("draw.create", this.drawCreateEvent);
      this.map.on("draw.update", this.drawEvent);
      this.map.on("draw.delete", this.drawEvent);
    },
    // The method is triggered when map was loaded
    async onMapLoad($event) {
      this.map = await $event.target;
      // eslint-disable-next-line
      this.map.setVisible = this.setVisible;
      // eslint-disable-next-line
      this.map.changeBaseLayer = this.changeBaseLayer;
      await this.initDrawTool();

      if (this.value) {
        this.featuresByValue();
        this.drawEvent(false);
      }

      window.addEventListener('keydown', this.keyboardEvents)

      this.$emit("load", this.map);
    },
    // The major method which using when you create a new geometry on the map
    drawEvent(emit = true) {
      this.featuresList = this.formatFeatures(this.draw.getAll().features);

      if (emit) this.$emit("input", this.emitDataByType(this.featuresList));
    },
    // Set geometry to draw by value
    featuresByValue() {
      const emitAll = this.multipleAll;
      
      if (emitAll) {
        const features = this.value?.features || this.value
        features.forEach((feature) => this.draw.add(feature));
        return;
      }
      const type = this.value.type.replace("Multi", "");
      // create method name by geometry type to use turf library
      const methodName = type.replace(type[0], type[0].toLowerCase());

      if (this.value.type.match("Multi")) {
        const geometry = this.value.coordinates;

        geometry.forEach((coordinates) => {
          // uses turf library
          this.draw.add(turf[methodName](coordinates));
        });

        return;
      }

      this.draw.add(this.value);
    },
    // Return data that formates by type
    emitDataByType(features) {
      if (!features.length) return null;

      const emitAll = this.multipleAll;

      // If mulitple-all props equal true, return all feature
      if (emitAll) return features;

      // Like I said if we have multiple geometry, all types will be the same, so we just need to format it into multi feature type
      if (!emitAll && this.multiple && features.length > 1) {
        const type = features[0]?.geometry?.type || "";
        const method = `multi${type}`;
        const featuresGeometry = features.map((feature) => feature.geometry.coordinates);

        const { geometry } = turf[method](featuresGeometry);

        return geometry;
      }

      return features[0].geometry;
    },
    // Create features array with all condition for DB, here must be multiple, once, multipleAll*
    formatFeatures(features) {
      if (this.multiple) {
        const geometry = features.reduce((prev, feature) => {
          if (feature.geometry.type !== features[0].geometry.type && !this.multipleAll) {
            this.$vsNotify({
              type: "error",
              title: "Помилка!",
              message: "У режимі мульти-геометрії можливо додавати лише декілька геометрій одного типу!",
            });
            this.draw.delete(feature.id);
            return prev;
          }

          return prev.concat(feature);
        }, []);

        return geometry;
      }
      return features;
    },
    // Initiating click on tools :)
    toolClick({ mode }) {
      // If mode isn't multiple, will delete all geometry on the map
      if (!this.multiple) {
        this.draw.deleteAll();
        this.drawEvent();
      }

      this.activeTool = this.activeTool === mode ? "" : mode;

      this.draw.changeMode(mode);
    },
    // Click on the map for assign features collection to data, and you can add something additional
    handleMapClick() { 
      this.getAllSelectedFeatures()
    },
    getAllSelectedFeatures() { 
      // Gett all selected features on the map
      const { features } = this.draw.getSelected()
      // We need only id, because if we save all objects, it will cause to less performance
      this.selectedFeatures = features.map(feature => feature.id)
    },  
    // aaaaand here is pretty clear too :)
    deleteFeature({ id }) {
      this.draw.delete(id);
      this.drawEvent();
    },
    keyboardEvents(event) {
      const key = event.keyCode
      switch (key) {
        case 27:
        this.activeTool = ''
          break;
      
        default:
          break;
      }
    },
    mapLevelOut() { 
      const { map } = this
      map.flyTo({
        pitch: 0,
        bearing: 0
      })
    }
  },
};
</script>

<style lang="scss">

.list-enter-active,
.list-leave-active {
  transition: transform 0.5s opacity 0.5s;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.form-geom {
  position: relative;

  .mapbox-gl-draw_ctrl-draw-btn {
    display: none;
  }

  &__search {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  &__panel {
    top: 60px;
    left: 10px;
    position: absolute;
  }

  &__features {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: 117px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 3px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #b5b5b5;
    }
    &::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }
  }

  &__feature {
    width: 100%;
    border-bottom: 1px solid rgb(249, 250, 251);
    padding: 5px 0;
 
  }

  &__tools {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &__tool {
    position: relative;
    width: 30px;
    height: 30px;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    i{
      font-size: initial;
    }
    &:hover {
      i {
        color: white;
      }
    }

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  &__layers {
    position: absolute;
    top: 60px;
    left: 11px;
    &-body {
      margin-top: 10px;
      .mapPortal__allLayer {
        position: relative;
        left: unset;
        top: unset;
        z-index: 1;
      }
    }
  }
  .maplibregl-popup-content.mapboxgl-popup-content{
    padding: 5px !important;
  }
}
</style>
