import { getVectorStyle } from './geojsonStyle.js';

export default {
  data() {
    return {
      layerList: [
        {
          id: 'graymap',
          name: 'Сіра карта',
          visible: true,
          service: 'tms',
          image: 'https://basemaps.cartocdn.com/light_all/10/601/348.png',
          url: 'https://basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
        },
      ],
      layerObj: {},
      colors: [
        'red',
        'blue',
        '#fbb03b',
        '#223b53',
        '#e55e5e',
        '#3bb2d0',
        'red',
        'blue',
        '#fbb03b',
        '#223b53',
        '#e55e5e',
        '#3bb2d0',
        'red',
        'blue',
        '#fbb03b',
        '#223b53',
        '#e55e5e',
        '#3bb2d0',
      ],
      activeLayer: '',
      addedLayersSettings: [],
      hoveredFeatureId: null,
    };
  },
  computed: {
    interactiveSources() {
      return this.layerList.filter((el) => el?.interactive).map((el) => el.id);
    },
  },
  methods: {
    moveLayers(id, map = this.map || this.$store.state.map.map) {
      map.getStyle().layers.forEach((el) => {
        if (el.id.match(id)) {
          map.moveLayer(el.id);
        }
      });
    },
    getLayersBySourceId(id, map) {
      if (!map) {
        map = this.$store.state.map.map;
      }
      const allLayers = map.getStyle().layers.filter((layer) => layer.id.match(id));
      return allLayers;
    },
    async setVisible(layer, visible = true, options = {}) {
      const layerIsObject = typeof layer === 'object';
      try {
        const { style, map = this.map || this.$store.state.map.map } = options;
        const sourceId = layer?.id || layer;

        const source = layerIsObject ? layer : await this.getLayerById(sourceId);

        if (!source?.id) throw new Error('MixinError: Layer has no id');

        const aviableLayers = map.getStyle().layers.filter((el) => el.id.match(sourceId));

        if (!aviableLayers.length || style) {
          await this.addToLayers(
            {
              ...{ ...source, ...(options?.data || {}) },
              interactive: source?.interactive !== undefined ? source?.interactive : true,
              visible,
            },
            { map, cb: options?.onAddLayer },
          );
        } else {
          this.layerList = this.layerList.map((layer) => {
            if (layer.id === source.id) {
              return { ...layer, visible };
            }
            return layer;
          });

          aviableLayers.forEach((item) => {
            map.setLayoutProperty(item.id, 'visibility', visible ? 'visible' : 'none');
          });
        }

        if (options?.cb) {
          await options?.cb(source);
        }
      } catch (error) {
        console.error(error);
        !options.onAddLayer
          || options.onAddLayer({
            data: !layerIsObject ? { id: layer } : layer,
            message: error.message,
            type: 'error',
          });
      }
    },
    async addToLayers(item, options = {}) {
      try {
        const { map = this?.map || this.$store.state.map.map } = options;
        const currentLayer = this.layerList.find((el) => el.id === item.id);

        if (!currentLayer) {
          this.layerList = this.layerList.concat(item);
        }

        await this.$set(this.layerObj, item.id, item);

        if (item.visible) {
          if (item?.layer) {
            await this.addVectorLayer(item, map);
          } else if (item.service === 'vtile') {
            await this.addVtileLayer(item, map);
          } else if (item.service === 'geojson') {
            await this.addGeojsonLayers(item, map);
          } else {
            this.addRasterLayer(item, map);
          }
        } else {
          map.getStyle().layers.forEach(async (el) => {
            if (el.source === item.id) {
              await map.setLayoutProperty(el.id, 'visibility', 'none');
            }
          });
        }

        !(options?.cb && item?.visible)
          || (await options.cb({
            data: item,
            type: 'success',
            // message: 'Шар успішно створено!',
          }));

        this.moveLayers('gl-draw');
      } catch (error) {
        !options.cb || options.cb({ data: item, message: error.message, type: 'error' });
      }
    },
    addRasterLayer(source, map) {
      map.addSource(source.id, {
        type: 'raster',
        tiles: [source?.url || source?.url1],
        tileSize: 256,
        ...(source?.scheme ? { scheme: source?.scheme } : {}),
      });

      map.addLayer({
        id: source.id,
        type: 'raster',
        source: source.id,
        minzoom: 0,
        maxzoom: source?.maxzoom || 22,
      });
    },
    async addVtileLayer(source) {
      await this.createGeometryLayer({ data: source }, 'vector');
    },
    async addVectorLayer(source, map) {
      const resp = await fetch(source.layer);
      const data = await resp.json();
      const layerData = { ...source, source: source.id, layers: [] };

      Object.keys(data.sources).forEach(async (el) => {
        await map.addSource(source.id, {
          type: data.sources[el].type,
          maxzoom: data.sources[el].maxzoom,
          url: data.sources[el].url,
        });
      });

      await data.layers.forEach(async (el) => {
        await map.addLayer({
          ...el,
          source: source.id,
          id: `${el.id}-${source.id}`,
        });

        layerData.layers = await layerData.layers.concat(`${el.id}-${source.id}`);
      });
    },
    async addGeojsonLayers(source) {
      const showError = (message) => {
        this.$vsNotify({
          type: 'warning',
          title: 'Відсутня геометрія у обраному шарі',
          message,
          position: 'bottom-right',
        });
        this.layerList = this.layerList.filter((el) => el.id !== source.id);
        throw new Error(message);
      };

      if (typeof source?.geojson === 'object') {
        this.createGeometryLayer({ data: source, geojson: source.geojson }, 'geojson');

        return;
      }

      try {
        const resp = await fetch(source?.geojson || source?.url);
        const data = await resp.json();

        if (resp.ok) {
          this.createGeometryLayer({ data: source, geojson: data }, 'geojson');
        } else {
          throw new Error(data?.message || resp?.statusText || 'Error addGeojson layer');
        }
      } catch (err) {
        showError(err.message);

        console.log(err);
      }
    },
    async createGeometryLayer({ data, geojson }, type) {
      const { map } = this;

      const sd = await getVectorStyle(
        data.id,
        this.layerList.find((el) => el.id == data.id),
        map,
      );

      const source = {
        type,
        cluster: data?.style?.cluster || false,
        clusterMaxZoom: data?.style?.clusterMaxZoom || 14,
      };

      if (type === 'vector') {
        source.tiles = [
          `${window.location.origin}${data?.geojson || data?.url}${data?.version ? `?ver=${data.version}` : ''}${
            data.props ? `&props=${data.props}` : ''
          }${data?.style?.pointZoom ? `&pointZoom=${data.style.pointZoom}` : ''}`,
        ];
      }

      if (type === 'geojson') {
        source.data = geojson || `${window.location.origin}${data?.geojson || data?.url}`;
      }

      map.addSource(data.id, source);

      sd.forEach((el) => {
        const layer = {
          ...el,
          source: data.id,
          maxzoom: +el?.maxzoom || 24,
          minzoom: +el?.minzoom || 0,
          cluster: false,
          clusterMaxZoom: 2,
        };
        map.addLayer(layer);
        map.moveLayer(el.id);
        if (!el?.layout?.['text-field']) {
          this.apiLayersHover(el.id, data.id, map);
        }
        this.addedLayersSettings = this.addedLayersSettings.concat(layer);
      });

      const aviableTypes = sd.map((item) => item.type).filter((el) => el && el !== 'symbol');

      if (data?.meta?.geom?.geometry) {
        const missingTypes = data.meta.geom.geometry.filter(
          (el) => !aviableTypes.includes(this.geometryByType(el, true)),
        );

        missingTypes.forEach((el) => {
          this.createStyleObj({ type: el, style: data.style }, data.id);
        });
      }
    },
    async setStyle(source, styleObject, map = this.map) {
      const id = source?.id || source;
      map.getStyle().layers.forEach((el) => {
        if (el.source === id) {
          map.removeLayer(el.id);
        }
      });

      if (map.getSource(id)) {
        map?.removeSource(id);
      }

      this.layerList = this.layerList.map((el) => {
        if (el.id === id) {
          el.style = styleObject;
        }

        return el;
      });

      const layerData = this.layerList.find((el) => el.id === id);
      this.addToLayers(layerData);
    },
    geometryByType(type, reverse = false) {
      if (type === 'fill' || type === 'Polygon' || type === 'MultiPolygon') {
        return !reverse ? 'Polygon' : 'fill';
      }
      if (type === 'circle' || type === 'Point' || type === 'MultiPoint') {
        return !reverse ? 'Point' : 'circle';
      }
      if (type === 'line' || type === 'LineString' || type === 'MultiLineString') {
        return !reverse ? 'LineString' : 'line';
      }
    },
    async removeLayer(layer, map = this.map) {
      const id = layer?.id || layer;

      this.layerList = this.layerList.filter((el) => el.id !== id);

      await map.getStyle().layers.forEach(async (el) => {
        if (el.source === id) {
          await map.removeLayer(el.id);
        }
      });

      await map.removeSource(id);
    },
    async reloadUrl(source, map = this.map) {
      const id = source?.id || source;
      await map.getStyle().layers.forEach((el) => {
        if (el.id === id) map.removeLayer(el.id);
      });

      await map.removeSource(id);

      await this.setVisible(source, true);
    },
    async setFilter(id, filter) {
      const currentLayer = this.layerObj[id];
      const source = await this.map.getSource(id);

      // filter server side

      if (currentLayer.service === 'vtile') {
        // vtile

        await source.setTiles([
          `${window.location.origin}${currentLayer.geojson || currentLayer.url}${
            currentLayer.url.includes('?') ? '&' : '?'
          }filter=${filter}${currentLayer.props ? `&props=${currentLayer.props}` : ''}`,
        ]);
      } else if (currentLayer.service === 'geojson') {
        // geojson
        const resp = await fetch(currentLayer?.geojson || `${currentLayer?.url}?filter=${filter}`);
        const data = await resp.json();

        await this.map.getSource(id).setData(data);
      }
    },
    async getLayerById(layer, cb = null) {
      const id = layer?.id || layer;
      try {
        const layerFromList = this.layerList?.find((el) => el.id === id);

        if (layerFromList) {
          return layerFromList;
        }

        const resp = await fetch(`/api-user/gis-ir/${id}${layer?.service ? `?service=${layer?.service}` : ''}`);

        if (resp.ok) {
          const layerData = await resp.json();
          !cb || (await cb(layerData));
          return layerData;
        }
        this.$vsNotify({
          type: 'warning',
          message: `Шар ${id} не було знайдено!`,
        });
        return null;
      } catch (error) {
        this.$vsNotify({
          type: 'warning',
          message: `Шар ${id} не було знайдено!`,
        });
      }
    },
    createStyleObj({ type, style }, id) {
      const layerType = this.geometryByType(type, true);

      if (!layerType) {
        throw Error('Geomery type ERROR: Error adding missing layers');
      }

      const layerId = `${id}-${layerType}`;

      if (this.map.getStyle().layers.find((el) => el.id === layerId)) return;

      const layer = {
        id: layerId,
        type: layerType,
        source: id,
        'source-layer': id,
        layout: {},
        paint: {
          [`${layerType}-color`]: [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            'red',
            style?.color || '#444e3d',
          ],
          [`${layerType}-opacity`]: style?.opacity || layerType === 'fill' ? 0.5 : 1,
        },
        filter: ['all', ['==', ['geometry-type'], this.geometryByType(type)]],
      };

      this.map.addLayer(layer);
      this.addedLayersSettings = this.addedLayersSettings.concat(layer);

      this.apiLayersHover(layerId, id, this.map);
    },
    apiLayersHover(layerName, source, map) {
      const sourceType = map.getSource(source);

      const baseSettings = () => {
        const settings = { source, id: this.hoveredFeatureId };
        if (sourceType.type === 'vector') {
          settings.sourceLayer = source;
        }
        return settings;
      };

      map.on('mousemove', layerName, (e) => {
        if (e.features.length > 0) {
          if (this.hoveredFeatureId !== null) {
            map.setFeatureState(baseSettings(), { hover: false });
          }
          this.hoveredFeatureId = e.features[0].id;
          map.setFeatureState(baseSettings(), { hover: true });
        }
      });

      // When the mouse leaves the state-fill layer, update the feature state of the
      // previously hovered feature.
      map.on('mouseleave', layerName, () => {
        if (this.hoveredFeatureId !== null) {
          map.setFeatureState(baseSettings(), { hover: false });
        }
        this.hoveredFeatureId = null;
      });
    },
    async changeBaseLayer(layer, visible = true, hide = true) {
      await this.setVisible(layer, visible);
      const id = layer?.id || layer;

      if (id === this.activeLayer) return;

      if (hide && this.activeLayer) await this.setVisible(this.activeLayer, false);

      this.map.getStyle().layers.forEach((el) => {
        if (el.source !== id && el.type !== 'background') {
          this.map.moveLayer(el.id);
        }
      });
      this.activeLayer = id;
    },
    async refreshLayer(layer, map = this.map) {
      const source = layer?.id || layer;

      await map.getStyle().layers.forEach((item) => {
        if (item.source === source) {
          map.removeLayer(item.id);
        }
      });

      await map.removeSource(source);

      this.layers = await this.layers.filter((el) => el.id !== source);

      await this.setVisible(typeof layer === 'object' ? layer : source);
    },
  },
};
