<template>
  <div
    ref="toast"
    class="vst-toast flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow"
    role="alert"
  >
    <div
      class="vst-toast__icon inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg"
      :class="'text-' + themeColor + '-500 bg-' + themeColor + '-100'"
    >
      <i
        v-if="type === 'success'"
        class="ti ti-circle-check-filled"
      />
      <i
        v-else-if="type === 'error'"
        class="ti ti-circle-x-filled"
      />
      <i
        v-else-if="type === 'warning'"
        class="ti ti-alert-circle-filled"
      />
      <i
        v-else
        class="ti ti-info-circle-filled"
      />
    </div>
    <div class="ml-3">
      <span
        v-if="title"
        class="mb-1 text-sm font-semibold text-gray-900"
      >{{ title }}</span>
      <div
        v-if="!html"
        class="text-sm font-normal"
      >
        {{ message }}
      </div>
      <div
        v-else
        v-html="message"
      />
    </div>
    <button
      type="button"
      class="ml-auto transition-all -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8"
      data-dismiss-target="#toast-success"
      aria-label="Close"
      @click="$refs.toast.parentElement.close()"
    >
      <span class="sr-only">Close</span>
      <svg
        class="w-3 h-3"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 14 14"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
        />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: 'Example',
    },
    message: {
      type: String,
      default: 'Example',
    },
    duration: {
      type: Number,
      default: 4500,
    },
    position: {
      type: String,
      default: 'top-right',
    },
    html: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      themeColor: '',
    };
  },
  created() {
    this.getThemeColor();
  },
  methods: {
    getThemeColor() {
      switch (this.type) {
        case 'error':
          this.themeColor = 'red';
          break;
        case 'warning':
          this.themeColor = 'orange';
          break;
        case 'success':
          this.themeColor = 'green';
          break;
        default:
          this.themeColor = 'blue';
          break;
      }
    },
  },
};
</script>

  <style lang="scss">
  .vst-toast {
    width: 350px;
    &__icon {
      font-size: 26px;
    }
  }
  </style>
